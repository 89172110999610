/* eslint-disable react-hooks/exhaustive-deps */
import { CCard, CCardBody, CCardHeader,  CModal, CModalBody, CModalHeader, CModalTitle, CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from "@coreui/react";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";
import { getTokenFromLocalStorage, getUserEmailFromLocalStorage, getUserLoginPrefixFromLocalStorage, getZoneFromLocalStorage } from "../../../../Services/LocalStorageService";
import { v4 as uuidv4 } from 'uuid';
import { LoggerContext } from "../../../WMS/Common_Helper/services/uiLoginServices";
import PubLinkCell from "./PublicLinkCell";
import createInterceptorInstance from "../../../../Services/Interceptor";
import { aquaExportHeaderMappins } from "../../../Grid/Common_Components/headerFieldNames";
import AInfraGrid from "../../../Grid/Common_Components/AInfraGrid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import AButton from "../../../../Components/AButton";
import { useSelector } from "react-redux";
import JSONPretty from "react-json-pretty";
import { getUserAccessPermission, readPermissionMessage } from "../../../../Services/UserAccessPermission";
import { getRandomAlphaNumericString } from "../../../Grid/Common_Components/grid-functions";
import { GetRequest } from "../../../Setup/Access.service";
import { AppEnv, genericAimsApi, getJobListAimsApi } from "../../../WMS/Common_Helper/API_Endpoints";

export const JobList = () => {
    const { logger } = useContext(LoggerContext);
    const userEmail = getUserEmailFromLocalStorage();
    const location = useLocation();
    const clientAccount = getUserLoginPrefixFromLocalStorage();
    const history = useHistory();
    const [response,setResponse] = useState([])
    const [selectedData , setSelectedItems] = useState([]);
    const [warning, setWarning] = useState(false);
    const darkMode = useSelector((state) => state.darkMode);
    const [isLoading,setIsLoading] =useState(false);
    const [deleteResponse, setDeleteResponse] = useState([]);
    const [successPopUp,setSuccessPopup] = useState(false);
    const [failedPopUP,setFailedPopUP] = useState(false);
    const [refresh,setRefresh] = useState(0);
    const [clearSelected,setClearSelected]=useState(false);
    const [isUserHasOnlyReadAccess,setIsUserHasOnlyReadAccess]=useState(true);

    const fetchPubLink = async (resp) => {
      const bgResponse = await Promise.all(
        resp?.data?.value?.map((item) => {
          const jobUri = item?.scheduledJobAction?.uri;
          if (jobUri?.includes("backgroundjob")) {
            const jobId = jobUri?.split("/")?.slice(-2, -1)?.[0];
            if (jobId) {
              return GetRequest(genericAimsApi, logger, false, {
                AIMS360APIURL: `${getJobListAimsApi}/backgroundjob?$filter=jobId eq '${jobId}'&$select=jobID,jobStatus,publishLink,clientID,startDate, completedDate`,
                Environment: AppEnv,
              });
            } else {
              return {
                data: {
                  value: [
                    {
                      publishLink: "Not Found",
                    },
                  ],
                },
              };
            }
          } else {
            return {
              data: {
                value: [
                  {
                    publishLink: "Not Found",
                  },
                ],
              },
            };
          }
        })
      );
      resp?.data?.value?.map((item, index) => {
        item["pubLink"] = bgResponse?.[index]?.data?.value?.[0]?.publishLink;
        item["ID"] = item?.id;
      });
      setResponse(resp);
      return resp;
    };
    
    //Render publish link as customcolumn
    const renderPublishLink =(event)=>{
        const data = event?.dataContext?.cell?.row?.data;
        return PubLinkCell(data);
    }
    useEffect(()=>{
        if(clearSelected){
            setClearSelected(false)
        }
    },[clearSelected])

    //chacking user permissions
    useEffect(()=>{
        let isAccess=getUserAccessPermission("AIMS360OTSAJ01");
        setIsUserHasOnlyReadAccess(isAccess);
    },[])

    //Page sizes
    const pagesizes = [
        { label: "100", value: "100" },
        { label: "250", value: "250" },
        { label: "500", value: "500" },
        { label: "All", value: 0 },
      ];


      //Grid Column properties
    const aquaGridColumns =[
        { name: "Created By", dataType: "String", width: "auto", minWidth: "0px" },
        { name: "Job Name", dataType: "String", width: "auto", minWidth: "0px" },
        { name: "Job Status", dataType: "String", width: "auto", minWidth: "0px" },
        { name: "Publishing URL", dataType: "String", width: "auto", minWidth: "0px",bodyTemplate:renderPublishLink },
        { name: "Job Description", dataType: "String", width: "auto", minWidth: "0px" },
        { name: "Scheduled Job ID", dataType: "String", width: "auto", minWidth: "0px" },
        { name: "Recurrence Type", dataType: "String", width: "auto", minWidth: "0px" },
        { name: "Start Date", dataType: "DateTime", width: "auto", minWidth: "0px" },
        { name: "ID", dataType: "String", width: "auto", minWidth: "0px" },
        { name: "Created Date", dataType: "DateTime", width: "auto", minWidth: "0px" },
        { name: "Frequency", dataType: "String", width: "auto", minWidth: "0px" },
        { name: "Interval", dataType: "Number", width: "auto", minWidth: "0px" },
        { name: "Days", dataType: "String", width: "auto", minWidth: "0px" },
        { name: "Hours", dataType: "String", width: "auto", minWidth: "0px" },
        { name: "Minutes", dataType: "String", width: "auto", minWidth: "0px" },
    ]

    //Adding aditional options
    const options = [
        { value: 'createJob', label: 'Create Job', },
        { value: 'editJob', label: 'Edit Job', disabled: selectedData?.length !== 1},
        { value: 'jobHistory', label: 'Job History', disabled: selectedData?.length !== 1 },
        { value: 'deleteJob', label: 'Delete Job', disabled: selectedData?.length === 0,isUserHasOnlyReadAccess:isUserHasOnlyReadAccess, readPermissionMessage:readPermissionMessage },
    ];
    //Handle options
    const getOtherOptionChange = (value)=>{
        const item=response?.data?.value?.find(obj => obj?.ID === selectedData?.[0]?.ID)
        if(value === 'createJob'){
            history.push(`/reports/aquascheduler/joblist/createjob?loginprefix=${clientAccount}`)
        }
        if(value === 'editJob'){
            history.push({
                pathname:"/reports/aquascheduler/joblist/Edit",
                search: `?loginprefix=${clientAccount}`,
                source: location?.pathname,
                origin: item,
                modalData: item,
            });
            localStorage.setItem("payloadSetting", JSON.stringify(item));
        }
        if(value === 'jobHistory'){
            localStorage.setItem("jobId", item?.pubLink?.split("/")[6]);
            history.push({
                pathname: "/reports/aquascheduler/joblist/history",
                search: `?loginprefix=${clientAccount}`,
                jobId: item?.pubLink?.split("/")[6],
            });
        }
        if(value === 'deleteJob'){
           setWarning(true);
        }
    }

    //Appending expand columns data
    const modifyColumnData = (newKey, parts, value, item) =>{
        if(newKey==='recurrence__week'){
            item['Days'] = value?.days ? JSON.stringify(value?.days) : null
            item['Hours'] = value?.hours ? JSON.stringify(value?.hours) : null
            item['Minutes'] = value?.minutes ? JSON.stringify(value?.minutes) : null

        }
        return item;
    }

    const getFilterQueryAqua=[
        {label: '{createdBy}',value:userEmail || ''},
    ]
    //Multiple delete Aqua jobs
    const deleteJobs = () =>{
        setIsLoading(true);
        let payload = selectedData.map(({ID})=>{
            return ID;
        })
        const guid = uuidv4();
        let zone = getZoneFromLocalStorage(); 
        let url = "";
        if (zone === "East") {
            url = process.env.REACT_APP_AQUASCHEDULER_URL_EAST
        } else {
            url = process.env.REACT_APP_AQUASCHEDULER_URL_WEST
        }
        const URL = createInterceptorInstance(`${url}/AquaScheduler/DeleteJob`);
        URL.delete('', { headers: { "Authorization": `Bearer ${getTokenFromLocalStorage()}` },data: payload })
        .then((res) => {
            logger('debug',guid, res, "AIMS360RunwayAquaScheduler", `${url}/AquaScheduler/DeleteJob`,"DELETE");
            let response =res?.data
            setDeleteResponse(response)
            if(res?.status === 200){

                let failedDelete = response?.data?.some(obj => obj.isSuccess === false);
                if(failedDelete){
                    setSuccessPopup(false);
                    setFailedPopUP(true);
                }else{
                    setSuccessPopup(true);
                    setFailedPopUP(false)
                }
                setRefresh(pre=>pre+1)
            }
            else if(res?.status === 207){
                setSuccessPopup(false);
                setFailedPopUP(true);
                setRefresh(pre=>pre+1)
            }
            setSelectedItems([]);
            setClearSelected(true)
        }).catch((ex) => {
            logger('error',guid, ex, "AIMS360RunwayAquaScheduler", `${url}/AquaScheduler/DeleteJob`,"DELETE");
            console.log(ex,"excepion");
            setSuccessPopup(false);
            setFailedPopUP(true);
            setDeleteResponse(ex?.response?.data)
        }).finally(()=>{
            setIsLoading(false);
        })
        
    }

    let filter =[
        {
            "id": getRandomAlphaNumericString(10),
            "key": "DefaultLeftKey",
            "value": "(",
            "operator": "",
            "condition": "",
            "isExpSelected": false,
            "type": "Group",
            "isReadable": false
        },
        {
            "id": getRandomAlphaNumericString(10),
            "key": "scheduledJobCategoryID",
            "value": "1005",
            "operator": "eq",
            "condition": "",
            "isExpSelected": false,
            "type": "Expression",
            "isReadable": true
        },
        {
            "id": getRandomAlphaNumericString(10),
            "key": "",
            "value": "",
            "operator": "",
            "condition": "and",
            "isExpSelected": false,
            "type": "Condition",
            "isReadable": true
        },{
            "id": getRandomAlphaNumericString(10),
            "key": "scheduledJobSubCategoryID",
            "value": "1005",
            "operator": "eq",
            "condition": "",
            "isExpSelected": false,
            "type": "Expression",
            "isReadable": true
        },
        {
            "id": getRandomAlphaNumericString(10),
            "key": "",
            "value": "",
            "operator": "",
            "condition": "and",
            "isExpSelected": false,
            "type": "Condition",
            "isReadable": true
        },{
            "id": getRandomAlphaNumericString(10),
            "key": "createdBy",
            "value": "{createdBy}",
            "operator": "eq",
            "condition": "",
            "isExpSelected": false,
            "type": "Expression",
            "isReadable": true
        },
        {
            "id": getRandomAlphaNumericString(10),
            "key": "DefaultRightKey",
            "value": ")",
            "operator": "",
            "condition": "",
            "isExpSelected": false,
            "type": "Group",
            "isReadable": false
        }
    ]


    return (
        
        <>
            <Helmet>
                <title>AIMS360 - Reports AquaScheduler JobList</title>
            </Helmet>
            <CCard>
            {warning && (
          <CModal show={warning} onClose={() => { setWarning(false); }}>
            <CModalHeader closeButton>
              <CModalTitle className={darkMode ? "text-center" : "popup-text text-center"}>Alert</CModalTitle>
            </CModalHeader>
            <CModalBody className={darkMode ? "" : "popup-text"}>

                {
                    successPopUp &&(
                        <>
                            <div class="alert alert-success d-flex align-items-center" role="alert">
                                <div className="mr-3"><FontAwesomeIcon icon={faCircleCheck} size="xl" className="successIcon" /></div>
                                <div dangerouslySetInnerHTML={{ __html: deleteResponse?.message }}></div>
                            </div>
                            <div className="d-flex justify-content-center">
                                <div className="w-25">
                                    <AButton className="w-75" color="success" text="Ok" onClick={() => { setSuccessPopup(false);setWarning(false); }} />
                                </div>
                            </div>
                        </>
                    )

                }
                {
                    failedPopUP && 
                    (<>
                     <CTabs activeTab="Summary">
                            <CNav variant="tabs">
                                <CNavItem>
                                    <CNavLink className={`${darkMode?"text-white":"text-dark"}`} data-tab="Summary">
                                        Summary
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink className={`${darkMode?"text-white":"text-dark"}`} data-tab="Details">
                                        Details
                                    </CNavLink>
                                </CNavItem>
                            </CNav>
                            <CTabContent>
                                <CTabPane className={"p-2"} data-tab="Summary">
                                    <div class="alert alert-danger" role="alert">
                                        <small>Status Code: {deleteResponse?.statusCode}</small> <br></br>
                                        <small> Message: {deleteResponse?.message}</small>
                                        
                                    </div>
                                </CTabPane>
                                <CTabPane className={"p-2"} data-tab="Details">
                                    <div class="alert alert-danger" role="alert">
                                        < JSONPretty id="json-pretty" className="jsonObject" color='red' data={deleteResponse?.data?.results || deleteResponse?.data}></JSONPretty>
                                    </div>
                                </CTabPane>
                            </CTabContent>
                        </CTabs>
                        <div className="text-right">
                            <AButton
                                color="danger"
                                onClick={() => {
                                    setWarning(false);
                                    setFailedPopUP(false);
                                }}
                                text="Close"
                            />
                        </div>
                    </>)
                }
                               
                  {(!successPopUp && !failedPopUP)&& 
                    (<>

                    <div class="alert alert-warning d-flex align-items-center" role="alert">
                      <div className="mr-3">
                        <FontAwesomeIcon icon={faTriangleExclamation} size="xl" className="warningIcon" />
                      </div>
                      <div>
                        Are you sure you want to delete {selectedData?.length} job(s)?
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <AButton color="secondary" text="No" className="mr-2" disabled={isLoading} onClick={() => { setWarning(false) }} />
                      <AButton
                        color="success"
                        text='Yes'
                        onClick={() => { deleteJobs() }}
                        disabled={isLoading}
                        isLoading={isLoading} 
                      >
                      </AButton>

                    </div>
                  </>)}
              
            </CModalBody>
          </CModal>
        )}
                <CCardHeader>
                    Aqua Job List
                </CCardHeader>
                <CCardBody className='px-0 pt-0'>
                    
                <AInfraGrid
                    ModuleMenuCode="AIM360AQSCH01"
                    filteringNumberAndGuidKeys={["scheduledJobCategoryID","scheduledJobSubCategoryID","scheduledJobID",'ID']}
                    pagesizes={pagesizes}
                    HeaderKeyMappings={aquaExportHeaderMappins}
                    viewsWithTabs={false}
                    columnsToModify={['week']}
                    nestedArrayToModify={['week']}
                    DefaultFilters={JSON.stringify(filter)}
                    modifyColumnData={modifyColumnData}
                    searchPlaceHolder="Job Name, Created By"
                    DefaultViewName="Aqua Job List"
                    gridPrimaryKey="ID"
                    gridColumns={aquaGridColumns}
                    DynamicFilters={getFilterQueryAqua}
                    activeViewName="Aqua Job List"
                    urlEndPoint="/scheduledjob"
                    searchFilter={"contains(createdBy, '{search}') or contains(scheduledJobName, '{search}')"}
                    selectRowOnClick={false}
                    keysToRemove={['Uri','Value']} 
                    othersOptions={options}
                    isPageSize={false}
                    customRenderedColumns={['Publishing URL']}
                    getOtherOptionChange={getOtherOptionChange}
                    dependentApi={fetchPubLink}
                    additionalOdataParamsForAPI="$orderby=createdDateTime desc" 
                    getSelectedItems={setSelectedItems}  
                    refresh={refresh}
                    isEmptySelected={clearSelected}   
                    isUserHasOnlyReadAccess={isUserHasOnlyReadAccess}
                    filterOperatorsNumberKeysOnly={["Scheduled Job ID"]}  
                    excludeFilterColumnKeys={['Publishing URL',"Minutes","Hours","Days"]}
                    isGenericAimsAPI={true}
                />
                
                    
                </CCardBody>


            </CCard>
        </>
    )
}
