import { CButton, CCard } from "@coreui/react";
import { useState } from "react";
import GridSidebar from "./grid-sidebar";
import { useSelector } from "react-redux";
import { Box, Button } from "@mui/material";
//GridSelectionPanel
function SelectionFields({
  layoutUpdated,
  getSelectedTabs,
  gridRef,
  gridStateRef,
  activeTab,
  views,
  setViews,
  ModuleMenuCode,
  gridData,
  excludedKeys,
  updatingStatesToEmpty,
  primaryKeys,
  removingExcessData,
  viewsWithTabs,
  viewsTabs,
  expandCheck1,
  defaultTab,
  expandValue1,
  getUpdatedViews,
  HeaderKeyMappings,
  FilterActionLayoutInit,
  gridCheckBox,
  getFilterClicks,
  isUserHasOnlyReadAccess,
  setIsLoading,
  DynamicFilters=[],
  excludeFilterColumnKeys="",
  gridColumns=[],
  filterOperatorsNumberKeysOnly=[],
  previewView=null,
  urlEndPoint
}) {
  const list = [
    { label: "Views", value: "views", disabled: false },
    { label: "Filters", value: "filters", disabled: false },
    // { label: "Grid View", value: "gridView", disabled: true },
  ];
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarProblem, setSidebarProblem] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const darkMode = useSelector((state) => state.darkMode);
  const handleGridView = (value) => {
    setSidebarProblem(true);
    setSidebarOpen(true);
    setSelectedValue(value);
  };
  return (
    <Box display="flex" width="100%">
       <Box display="flex" flexDirection={{xs: 'row', md: 'column'}} gap={{xs: '12px', md: 'unset'}} width="100%">
            {list?.map((ele) => (
              <Button
                onClick={() => handleGridView(ele.value)}
                className={`text-nowrap d-flex rounded-0 viewFields ${
                  !darkMode && "text-primary"
                }`}
                key={ele.value}
                disabled={ele.disabled}
                sx={{
                  display:"flex",
                  textTransform: 'capitalize',
                  fontSize: '14px',
                  lineHeight: '21px',
                  color: '#321FDB',
                  gap: '8px',
                  bgcolor: '#fff',
                  flexDirection: "row",
                  boxShadow: {
                    xs: "0px 1px 3px 0px #3C4B6433, 0px 2px 1px -1px #3C4B641F, 0px 1px 1px 0px #3C4B6424",
                    md: "0px 1px 3px 0px #3C4B6433, 0px 2px 1px -1px #3C4B641F, 0px 1px 1px 0px #3C4B6424"
                  },
                  flex: {
                    xs: 1,
                    md: "unset"
                  },
                  margin: {md: 0},
                  padding: {md: "2px 12px"},
                  minWidth: {md: "131px"},
                  "&:first-child": {
                    borderRadius: {
                      md: "6px 6px 0 0 !important"
                    },
                  },
                  "&:last-child": {
                    borderRadius: {
                      md: "0 0 6px 6px !important"
                    },
                  }
                }}
              >
                <div className="text-left w-75">{ele.label}</div>
                <div className="w-25">&gt;</div>
              </Button>
            ))}
        </Box>
      <GridSidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        selectedValue={selectedValue}
        getSelectedTabs={getSelectedTabs}
        layoutUpdated={layoutUpdated}
        gridRef={gridRef}
        gridStateRef={gridStateRef}
        activeTab={activeTab}
        views={views}
        setViews={setViews}
        ModuleMenuCode={ModuleMenuCode}
        gridData={gridData}
        excludedKeys={excludedKeys}
        updatingStatesToEmpty={updatingStatesToEmpty}
        primaryKeys={primaryKeys}
        sidebarProblem={sidebarProblem}
        removingExcessData={removingExcessData}
        viewsWithTabs = {viewsWithTabs}
        viewsTabs = {viewsTabs}
        expandCheck1 = {expandCheck1}
        defaultTab = {defaultTab}
        expandValue1 = {expandValue1}
        getUpdatedViews={getUpdatedViews}
        HeaderKeyMappings={HeaderKeyMappings}
        FilterActionLayoutInit={FilterActionLayoutInit}
        gridCheckBox={gridCheckBox}
        getFilterClicks={getFilterClicks}
        isUserHasOnlyReadAccess={isUserHasOnlyReadAccess}
        setIsLoading={setIsLoading}
        DynamicFilters={DynamicFilters}
        excludeFilterColumnKeys={excludeFilterColumnKeys}
        gridColumns={gridColumns}
        filterOperatorsNumberKeysOnly={filterOperatorsNumberKeysOnly}
        previewView={previewView}
        urlEndPoint={urlEndPoint}
      />
    </Box>
  );
}

export default SelectionFields;