import { SetHeaders } from '../WMS/Common_Helper/API_Endpoints';
import { getZoneFromLocalStorage } from '../../Services/LocalStorageService';
import { v4 as uuidv4 } from 'uuid';
import { dateFormatFromApitoUi } from '../../Services/DateForamt';
import createInterceptorInstance from '../../Services/Interceptor';
import axios from 'axios';
const GetZone = () =>{
    let currentUrl = window.location.href;
    let zonee = "";
   if(currentUrl.includes('zone=')){
    zonee = currentUrl?.split("zone=")[1];
    if(zonee?.includes('&')){
        zonee = zonee.split('&')[0];
    }
    localStorage.setItem('zone',zonee)
   }
    return zonee;
}
let zone = GetZone();
if(!zone){
    zone =  getZoneFromLocalStorage();
}
const BaseUrl = zone?.toLowerCase() === 'west'?`${process.env.REACT_APP_USERACCESS_URL_WEST}`:`${process.env.REACT_APP_USERACCESS_URL_EAST}`
const Users = BaseUrl+"Users?$orderby=userName"
const RolesList = BaseUrl+"Roles?$orderby=Name"
const AssignRoleToUser = BaseUrl+"Role/assignrole"
const DeleteRoleUrl = BaseUrl+"Role/deleteRole";
const RoleObjectUrl = BaseUrl+"Role/{roleid}"
const UpdateRoleUrl = BaseUrl+"Role/updaterole"
const updateSessionTimeUrl=BaseUrl+"Role/updatesessiontimeoutbyroleid"
const CreateRoleUrl = BaseUrl+"Role/createrole"
const FeatureUrl = BaseUrl+"Feature?$orderby=Module desc"
const DefaultRoleUrl = BaseUrl+"Role/defaultrole/{roleid}"
const UserAccess = BaseUrl+"Useraccess"
const UserByRole = BaseUrl + "Userrole?roleid={roleid}"

const fields = [
    { key: 'select', label: '', filter: false },
    { key: 'Username',filter: true },
    { key: "Assigned", filter: true },
    { key: "Description", filter: false },
    { key: "Created on", filter: false },
    { key: "Last Logged on", filter: false },
]

const wareHouseFields=[
    { key: 'warehouseName',filter: true,sorter :true,_style :{width:'20%'} },
    { key: "warehouseCode", filter:false,sorter :true ,_style :{width:'15%'}},
    { key: "Status", filter: true,sorter :true,_style :{width:'5%'} },
    { key: "Setting_Line_Item",label: 'Scan To Verify Line Item', filter: false,sorter:false,_style :{width:'5%'}},
    { key: "Setting_BIN_Location",label: 'Scan To Verify BIN Location', filter: false ,sorter:false,_style :{width:'5%'}},
    { key: "Setting_New_BIN_Location",label: 'Allow Change Bin Location On Pick Ticket', filter: false ,sorter:false,_style :{width:'5%'}},
    { key :"API-call-status",label:'',filter: false,_style :{width:'15%'},sorter:false},
]

const setTabledata = (data) => {
    let tableData = [];
    data?.forEach((item, index) => {
        tableData.push({
            "id": index++,
            "Username": `${nullChecker(item?.UserFirstName)} ${nullChecker(item?.UserLastName)}`,
            "Assigned": nullChecker(item?.Role?.RoleName),
            "Description": nullChecker(item?.Role?.Description),
            "Created on": dateFormatFromApitoUi(item?.CreatedOn),
            "Last Logged on":dateFormatFromApitoUi(item?.LastLoggedOn),
            "UserId": item?.UserId,
            "RoleId":item?.Role?.RoleId,
            "isChecked" : false,
            "UserEmail" :item?.UserName,
            "AdminType":item?.Role?.AdminType
        })
    })
    return tableData;
}

const wareHosueTabledata =(data)=>{
    let wareHousetableData = [];
    wareHousetableData.push({
        id: 0,
        warehouseName: '',
        warehouseCode: '',
        Status: '',
        Setting_Line_Item: true,
        Setting_BIN_Location: true,
        Setting_New_BIN_Location:true,
        button_visibility:false,
        warehouseId:'',
        isSaved:false,
        isFailed:false,
        isUpdating:false,
      });
    data?.forEach((item, index) => {
        wareHousetableData.push({
            "id": index+1,
            "warehouseName": item?.warehouseName,
            "warehouseCode": nullChecker(item?.warehouseCode),
            "Status":item?.status,
            "Setting_Line_Item":!!item?.verifyLine,
            "Setting_BIN_Location":!!item?.verifyBin,
            "Setting_New_BIN_Location":!!item?.verifyAllowUserToScanDifferentBinlocation,
            "button_visibility":item.button_visibility? item.button_visibility:false,
            "warehouseId":item?.warehouseID,
            "isSaved":false,
            "isFailed":false,
            "isUpdating":false,
        })
        
    })
    return wareHousetableData;
}
const GetRequest =(url,logger,cancelToken = false, customHeaders = {})=>{
    const guid = uuidv4();
    const source = axios.CancelToken.source();
    const URL = createInterceptorInstance(url);
    const requestPromise = URL.get('', {
        headers: {
            ...SetHeaders(),  // Existing headers
            ...customHeaders // Merge custom headers
        },
        ...(cancelToken && {cancelToken: source.token})
    })?.then(res => {
        logger('debug', guid, res, "AIMS360RunwayUserAccess", url, "GET"); // log request and response data
        return res;
    })
    .catch(error => {
        logger('error', guid, error, "AIMS360RunwayUserAccess", url, "GET"); // log error
        throw error;
    });
    if (cancelToken) {
        return {
            promise: requestPromise,
            cancel: source ? () => source.cancel('Request canceled') : null
        }
    } else {
        return requestPromise;
    }
   }
const PostRequest =(url, body,logger)=>{
    const guid = uuidv4();
    const URL = createInterceptorInstance(url);
    return URL.post('',body, SetHeaders())?.then(res => {
        logger('debug', guid, res, "AIMS360RunwayUserAccess", url, "POST",body); // log request and response data
        return res;
    })
    .catch(error => {
        logger('error', guid, error, "AIMS360RunwayUserAccess", url, "POST",body); // log error
        throw error;
    });
}
const PutRequest =(url, body,logger)=>{
    const guid = uuidv4();
    const URL = createInterceptorInstance(url);
    return URL.put('', body, SetHeaders())?.then(res => {
        logger('debug', guid, res, "AIMS360RunwayUserAccess", url, "PUT",body); // log request and response data
        return res;
    })
    .catch(error => {
        logger('error', guid, error, "AIMS360RunwayUserAccess", url, "PUT",body); // log error
        throw error;
    });
}

const DeleteRequest =(url, body,logger)=>{
    const guid = uuidv4();
    const URL = createInterceptorInstance(url);
    return URL.post('', body, SetHeaders())?.then(res => {
        logger('debug', guid, res, "AIMS360RunwayUserAccess", url, "DELETE",body); // log request and response data
        return res;
    })
    .catch(error => {
        logger('error', guid, error, "AIMS360RunwayUserAccess", url, "DELETE",body); // log error
        throw error;
    });
}

const nullChecker =(item)=>{
    if(item === null || item === undefined){
        return '';
    }else{return item}
}
const ModeObject={
    "Disabled" : 0,
    "ReadOnly" : 1,
    "Enabled" : 2,
    "0":"Disabled",
    "1":"ReadOnly",
    "2" :"Enabled"
}

export{
    GetRequest,
    PostRequest,
    PutRequest,
    DeleteRequest,
    Users,
    RolesList,
    AssignRoleToUser,
    DeleteRoleUrl,
    RoleObjectUrl,
    UpdateRoleUrl,
    CreateRoleUrl,
    FeatureUrl,
    nullChecker,
    ModeObject,
    DefaultRoleUrl,
    UserAccess,
    UserByRole,
    fields,
    setTabledata,
    wareHouseFields,
    wareHosueTabledata,
    updateSessionTimeUrl
}