/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { HashRouter, Route, Switch,useHistory } from 'react-router-dom';
import './scss/style.scss';
import Page403 from './views/pages/page403/Page403';
import LoggerProvider from './views/WMS/Common_Helper/services/uiLoginServices';
import { getIpAddress } from './views/WMS/Common_Helper/services/getIpAddress';
import { AquaScheduler, JobList, EditDetails, JobHistory } from "./views/ExternalModules";
import { Provider } from 'react-redux';
import store from './store';
import { getUserLoginPrefixFromLocalStorage } from './Services/LocalStorageService';
import { createTheme, ThemeProvider } from '@mui/material';
import { ToastContainer } from 'react-toastify';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const TheLayout = React.lazy(() => import('./containers/TheLayout'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const SchedulerEditor = React.lazy(() => import('./views/reportMail/reportHelper/scheduleEditor'))
const ERPCallback = React.lazy(() => import('./views/pages/aims-erp-login/ERPCallback'));
const UnderMaintenance = React.lazy(() => import('./views/pages/aims-erp-login/UnderMaintenance'));
const AccountIntegrationStatus = React.lazy(()=>import('./views/Integration/AccountIntegrationStatus') )
function App() {
  const history = useHistory();
  useEffect(() => {
    getIpAddress();

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    const realmId = urlParams.get('realmId');
    if (code && state === 'PlaygroundAuth' && realmId) {
      localStorage.removeItem('quickBooksAuth');
      const newUrl = `${window.location.origin}/#/qkSetup?code=${code}&state=${state}&realmId=${realmId}`;
      window.location.href = newUrl;
    }else{
      const backToRunway = localStorage.getItem('quickBooksAuth');
      if(backToRunway){
        const currentURL = window.location.href;
        const url = new URL(currentURL);
        const clientAccount = getUserLoginPrefixFromLocalStorage();
        let baseUrl = `${url.protocol}//${url.host}/#/Setup/accountintegration?loginprefix=${clientAccount}`
        window.location.href = baseUrl;
      }
    }

  }, [history]);


  const theme = createTheme({
    palette: {
      // ✅ Primary Brand Color (Main Blue)
      primary: {
        light: "#ADA6F2", // 200
        main: "#321FDB", // 500 (Main Primary)
        dark: "#1D1282", // 700
        contrastText: "#FFFFFF",
      },
  
      // ✅ Secondary Brand Color (Main Gray)
      secondary: {
        light: "#A9B6CB", // 200
        main: "#303C50", // 500 (Main Secondary)
        dark: "#191F29", // 700
        contrastText: "#FFFFFF",
      },
  
      // ✅ Status Colors (Success, Warning, Error, Info)
      success: {
        light: "#A8F0C0", // 200
        main: "#1FAD4E", // 600
        dark: "#0F5727", // 800
      },
      warning: {
        light: "#FFEDAD", // 200
        main: "#FAC400", // 600
        dark: "#7A6000", // 800
      },
      error: {
        light: "#F5ADBE", // 200
        main: "#C91741", // 600
        dark: "#640C20", // 800
      },
      info: {
        light: "#ADA6F2", // 200
        main: "#321FDB", // 500
        dark: "#1D1282", // 700
      },
  
      // ✅ Background & Text Colors
      // background: {
      //   default: "#0A062D", // Dark Mode Background
      //   paper: "#191F29", // UI Surface
      // },
      text: {
        primary: "#0A062D",
        secondary: "#303C50",
        disabled: "#7C8FB1",
        hint: "#A9B6CB",
      },
  
      // ✅ Custom Palette for Extended Color Variants
      customPalette: {
        // 🔵 Main Brand Colors - Blue
        mainBlue: {
          lightest: "#EBE9FC",
          lighter: "#D6D2F9",
          light: "#ADA6F2",
          mediumLight: "#8075EB",
          medium: "#5F4EFF",
          base: "#321FDB", // Main Blue
          mediumDark: "#2819AE",
          dark: "#1D1282",
          darker: "#140D59",
          darkest: "#0A062D",
          ultraDark: "#050316",
        },
      
        // ⚪ Neutral Gray Colors
        mainGray: {
          lightest: "#E9ECF2",
          lighter: "#D2D9E4",
          light: "#A9B6CB",
          mediumLight: "#7C8FB1",
          medium: "#566B8F",
          base: "#303C50", // Main Gray
          mediumDark: "#242D3D",
          dark: "#191F29",
          darker: "#0B0E13",
          darkest: "#06070A",
          ultraDark: "#040506",
        },
      
        // 🔵 Muted Blue-Gray Colors
        mainBlueGray: {
          lightest: "#F9FAFB",
          lighter: "#F9F9FB",
          light: "#EFF0F5",
          mediumLight: "#E0E2EB",
          medium: "#C1C4D7",
          base: "#B2B6CE",
          mediumDark: "#8289B0",
          dark: "#59618C",
          darker: "#3C415D",
          darkest: "#181D1F",
          ultraDark: "#040506",
        },
      
        // 💖 Fuchsia (Accent Color)
        fuchsia: {
          lightest: "#FBE9F5",
          lighter: "#F7D4EB",
          light: "#F0A8D7",
          mediumLight: "#E87DC3",
          medium: "#E052AE",
          base: "#DA279D",
          mediumDark: "#AD1F7B",
          dark: "#82175D",
          darker: "#570F3E",
          darkest: "#2B081F",
          ultraDark: "#16040F",
        },
      
        // ✅ Green (Success/Positive)
        green: {
          lightest: "#E9FBEF",
          lighter: "#D4F7E0",
          light: "#A8F0C0",
          mediumLight: "#7DE8A1",
          medium: "#52E081",
          base: "#1FAD4E", // Main Green
          mediumDark: "#17823B",
          dark: "#0F5727",
          darker: "#082B14",
          darkest: "#04160A",
          ultraDark: "#031008",
        },
      
        // 🛑 Red (Error/Alert)
        red: {
          lightest: "#FCE8ED",
          lighter: "#FAD6DE",
          light: "#F5ADBE",
          mediumLight: "#F1849D",
          medium: "#EC5B7C",
          base: "#C91741", // Main Red
          mediumDark: "#97122D",
          dark: "#640C20",
          darker: "#320610",
          darkest: "#170307",
          ultraDark: "#110205",
        },
      
        // 🟡 Yellow (Warning/Alert)
        yellow: {
          lightest: "#FFFBEB",
          lighter: "#FFF6D6",
          light: "#FFEDAD",
          mediumLight: "#FFE68A",
          medium: "#FFDD61",
          base: "#FAC400", // Main Yellow
          mediumDark: "#BD9400",
          dark: "#7A6000",
          darker: "#3D3000",
          darkest: "#1F1800",
          ultraDark: "#0F0C00",
        },
      },
    },
  });

  return (
    <>
      <Provider store={store}>
      <ThemeProvider theme={theme}>
        <React.Suspense fallback={loading}>
          <HashRouter>
            <LoggerProvider>
              <Switch>
                {/* <Route exact path="/" name="Login Page" render={props => <AIMS360ERP {...props} />} />
            <Route exact path="/login" name="Login Page" render={props => <AIMS360ERP {...props} />} /> */}
                <Route exact path="/undermaintenance" name="Client Page" render={props => <UnderMaintenance {...props} />} />
                {/* This Routes for Separate applications of Aqua Scheduler */}
                <Route exact path="/aquascheduler/create" name="Aqua Scheduler" render={props => <AquaScheduler {...props} />} />
                <Route exact path="/aquascheduler/joblist" name="Aqua JobList" render={props => <JobList {...props} />} />
                <Route exact path="/aquascheduler/editjob" name="Edit Details" render={props => <EditDetails {...props} />} />
                <Route exact path="/aquascheduler/Jobhistory" name="Aqua Job History" render={props => <JobHistory {...props} />} />
                <Route exact path="/aquascheduler/SchedulerEditor" name="Scheduler Editor" render={props => <SchedulerEditor {...props} />} />
                {/* This Routes for Separate applications of Aqua Scheduler */}
                <Route exact path="/qkSetup" name="Quick Book App" render={props => <AccountIntegrationStatus {...props} />} />
                <Route exact path="/clientcode" name="client page" render={props => <ERPCallback {...props} />} />
                <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
                <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
                <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
                <Route exact path="/403" name="Page 403" render={props => <Page403 {...props} />} />
                <Route path="/" name="Home" render={props => <TheLayout {...props} />} />                
                <Route exact path="*" name="Page 404" render={props => <Page404 {...props} />} />
             

              </Switch>
            </LoggerProvider>
          </HashRouter>
        </React.Suspense>
      </ThemeProvider>
      <ToastContainer />
      </Provider>
    </>
  );
}


export default App;