import { GetRequest } from "../views/Setup/Access.service";
import {
  genericAimsApi,
  warehouseURLAimsApi,
  wmsUpdateTicketUrl,
  AppEnv
} from "../views/WMS/Common_Helper/API_Endpoints";

export const getSingularOrPlural = (
  count: number = 1,
  singular: string,
  plural = null
) => {
  // If no plural is provided, assume it is the singular form with an added "s".
  const pluralForm = plural || `${singular}s`;
  return count === 1 ? singular : pluralForm;
};

/**
 * Utility to handle API redirection using fetch
 * @param {string} apiUrl - The URL to be checked for redirection
 * @returns {Promise<Object>} - Resolves with the final response data or an error
 */
export async function handleRedirection(apiUrl: string) {
  try {
    // Call the backend API to check for redirection
    const redirectionCheckResponse = await fetch(
      `${wmsUpdateTicketUrl}DownloadShippingLabel?url=${apiUrl}`
    );
    if (!redirectionCheckResponse.ok) {
      throw new Error("Failed to check redirection");
    }
    const data = await redirectionCheckResponse.text();
    if (data) {
      const redirectedResponse = await fetch(data);
      if (!redirectedResponse.ok) {
        throw new Error("Failed to fetch redirected URL");
      }
      return await redirectedResponse.text(); // Return the response from the new URL
    }
  } catch (error) {
    console.error("Error handling redirection:");
    throw error;
  }
}

export async function getWarehouseByRole({
  logger,
  warehouses
}: {
  logger: any;
  warehouses: {
    warehouseID: string;
  }[];
}): Promise<{ status: number; data: { value: any[] } }> {
  const allowedWarehouseIds = warehouses?.length
    ? warehouses.map((wh) => wh.warehouseID)
    : null;

  if (!allowedWarehouseIds) {
    const AIMS360APIURL = `${warehouseURLAimsApi}`;
    const response = (await GetRequest(genericAimsApi, logger, false, {
      AIMS360APIURL,
      "Environment": AppEnv,
    })) as any;

    return {
      status: response?.status ?? 200,
      data: { value: response?.data?.value ?? [] },
    };
  }

  // Function to split warehouse IDs into chunks of 25
  const chunkArray = (arr: string[], size: number) =>
    arr.reduce(
      (acc, _, i) => (i % size ? acc : [...acc, arr.slice(i, i + size)]),
      [] as string[][]
    );

  const chunks = chunkArray(allowedWarehouseIds, 1);

  // Create an array of API request promises
  const apiRequests = chunks.map((chunk) => {
    const AIMS360APIURL = `${warehouseURLAimsApi}?$filter=warehouseID in ('${chunk.join(
      "','"
    )}')`;

    return GetRequest(genericAimsApi, logger, false, { AIMS360APIURL, "Environment": AppEnv, });
  });

  const results = (await Promise.all(apiRequests)) as any;

  return {
    status: results?.[0]?.status ?? 200,
    data: { value: results.flatMap((res: any) => res?.data?.value ?? []) },
  };
}
