/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import {
  CCol,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CFormGroup,
  CRow,
  CTabContent,
  CTabPane,
  CTabs,
  CTooltip,
} from "@coreui/react";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import _ from "lodash";
import moment from 'moment-timezone';
import { getViews, postView } from "../Services/GridAPIService";
import { AddSpinner, RemoveSpinner } from "../../WMS/spinner";
import { LoggerContext } from "../../WMS/Common_Helper/services/uiLoginServices";
import { GetRequest } from "../../WMS/Common_Helper/services/Odata.orchestration.Service";
import { AppEnv, OdataOrchestrationUrl, genericAimsApi } from "../../WMS/Common_Helper/API_Endpoints";
import {
  SearchInput,
  flattenArrayOfObjects,
  generateUrl,
} from "./grid-functions";
import { getLabel, mobileMode } from "../../../Resources/content";
import handleErrorMessage, {
  HandleMessageFromResponse,
} from "../../../helpers/handleErrorMessage";
import AButton from "../../../Components/AButton";
import { dateFormatFromApitoUi } from "../../../Services/DateForamt";
import InfraGrid from "./InfraGrid";
import Pagination from "./pagination";
import {
  GetSystemSettings,
  SecretCodeForRoutes,
} from "../../../Services/LocalStorageService";
import TabLinks from "./tabLinks";
import SelectionFields from "./selectionFields";
import { CSVLink } from "react-csv";
import "../../Grid/Common_Components/grid.scss";
import { gridBaseUrl } from "./gridAPIConfiguration";
import { sortItems } from "./sortUtils";

import ClearIcon from "../../../assets/icons/clear-icon.svg"

const DropdownPortal = ({ children }) => {
  return ReactDOM.createPortal(children, document.body);
};

let checkSearch = false;
let removedPresentTab = false;
// Check if the device is in mobile mode
const isMobile = mobileMode();
/**
 * AInfraGrid component
 *
 * @param {Object} props - Component props
 * @param {string} [props.searchAllMode] - Search Mode
 * @param {string} [props.ModuleMenuCode] - Module menu code
 * @param {string} [props.activeViewName] - Active view name
 * @param {Object} [props.baseURL] - Base URL for API requests
 * @param {Array} [props.filteringNumberAndGuidKeys] - Keys for filtering numbers and GUIDs
 * @param {Array} [props.pagesizes] - Page sizes options
 * @param {boolean} [props.parentInteractionFlag] - Flag indicating parent interaction
 * @param {Array} [props.parentSelectedItems] - Parent selected items
 * @param {Object} [props.HeaderKeyMappings] - Header key mappings
 * @param {Function} [props.modifyColumnData] - Function to modify column data
 * @param {Array} [props.columnsToModify] - Columns to modify
 * @param {string} [props.parentPrimaryKey] - Parent primary key
 * @param {number} [props.maxPageSize] - maximum page size
 * @param {Function} [props.ExternalTab] - External tab component
 * @param {string} [props.TabClassNames] - Additional class names for tabs
 * @param {Function} [props.onTabChange] - Callback function for tab change
 * @param {boolean} [props.isSearchActive] - Flag indicating if search is active
 * @param {string} [props.searchPlaceHolder] - Placeholder text for search input
 * @param {boolean} [props.isExportActive] - Flag indicating if export is active
 * @param {Function} [props.performExportFromAPI] - Function to perform export from API
 * @param {Array} [props.keysToRemove] - Keys to remove from data
 * @param {Array} [props.previewDates] - Preview dates
 * @param {string} [props.DefaultViewName] - Default view name
 * @param {string} [props.DefaultCategory] - Default category
 * @param {string} [props.DefaultFilters] - Default filters
 * @param {string} [props.DefaultUrlExpand] - Default URL expand
 * @param {boolean} [props.isViewFilterActive] - Flag indicating if view filter is active
 * @param {boolean} [props.viewsWithTabs] - Flag indicating if views have tabs
 * @param {Array} [props.viewsTabs] - Views tabs
 * @param {boolean} [props.expandCheck1] - Expand check 1
 * @param {Object} [props.defaultTab] - Default tab
 * @param {string} [props.expandValue1] - Expand value 1
 * @param {boolean} [props.isUserHasOnlyReadAccess] - Flag indicating if user has only read access
 * @param {string} [props.gridPrimaryKey] - Grid primary key
 * @param {Array} [props.gridColumns] - Column configurations
 * @param {boolean} [props.ispaginationActive] - Flag indicating if pagination is active
 * @param {string} [props.additionalPagenationClassName] - Additional class name for pagination
 * @param {string} [props.parentDefaultViewId] - Parent default view ID
 * @param {string} [props.urlEndPoint] - url end point
 * @param {string} [props.additionalOdataParamsForAPI] - adding the odata params string like orderBy. $count is already added by default
 * @param {boolean} [props.isTabsActive] - it will active and deactive the tab functionlity
 * @param {boolean} [props.renderPrintDropDown] - it will render the print dropdown
 * @param {boolean} [props.renderSearchToggle] - it will render the toggle button
 * @param {boolean | promise} [props.dependentApi] - it will render the dependent api
 * @returns {JSX.Element} - Rendered component
 */
const AInfraGrid = ({
  ModuleMenuCode = "",
  searchAllMode = false,
  renderSearchToggle = null,
  activeViewName = "",
  refresh = "",
  baseURL = OdataOrchestrationUrl, // this is odata orchastration base URL dont need to send this property if it is not you need to send the base URL only not end point
  filteringNumberAndGuidKeys = [],
  pagesizes = [],
  parentInteractionFlag = false,
  parentSelectedItems = [],
  HeaderKeyMappings = {},
  modifyColumnData = null,
  columnsToModify = [],
  nestedArrayToModify = [], // this one is usig for any nested array data want to modify you need to send that key name.
  parentPrimaryKey = "",
  maxPageSize = 250,
  TabClassNames = "",
  onTabChange = null,
  searchPlaceHolder = "Enter value",
  performExportFromAPI = null,
  keysToRemove = [],
  previewDates = [],
  DefaultViewName = "",
  DefaultCategory = "",
  DefaultFilters = "",
  DefaultUrlExpand = "",
  viewsWithTabs = false,
  viewsTabs = [],
  expandCheck1 = false,
  defaultTab = { label: "", value: "" },
  expandValue1 = "",
  gridPrimaryKey = "",
  gridColumns = [],
  additionalPagenationClassName = "",
  parentDefaultViewId = "",
  isExportActive = true,
  isViewFilterActive = true,
  isSearchActive = true,
  ispaginationActive = true,
  isTabsActive = true,
  isUserHasOnlyReadAccess = false,
  urlEndPoint = "",
  additionalOdataParamsForAPI = "",

  allowFiltering = true,
  autoGenerate = false,
  filterMode = "ExcelStyleFilter",
  gridClassName = "",
  gridHeight = `calc(100% - ${isMobile ? "4%" : "2%"})`,
  gridWidth = "100%",
  moving = true,
  rowSelection = "Multiple",
  getSelectedItems = null,
  AInfraGridButtons = [],
  customRenderedColumns = [],
  apiExpandValues = [],
  rowStyles = null,
  rowClasses = null,
  selectRowOnClick = true,
  onGridRef = null,
  searchFilter = "",
  othersOptions = [],
  getOtherOptionChange = null,
  isPageSize = true,
  getResponse = null,
  notOdata = false,
  expandFilterConstructor = null,
  expandParameterKeys = [],
  DynamicFilters = [],
  getAPIcallFunction = null,
  getActivePage = null,
  renderWarehouseDropDown = null,
  renderPrintDropDown = null,
  isWareHouseDropDown = false,
  specificSubCategory = [],
  viewSubcategory = "",
  exportOrderBy = [],
  updateGridData = [],
  customizeGridData = null,
  isEmptySelected = false,
  directPropertyPath = [],
  excludeFilterColumnKeys="", // in filter section any column don't want to show use this as array of strings
  ReFetchAPI = false, //for recall the fetch method to get the details
  isUrlDoubleEncode = true,
  insertParentPage=0,
  customFilterValues=[],
  isCallNextLinksForAllData=false,
  allDataForRunwayAPIs=false,
  getResponseRenamed=null,
  filterValueTransformations={},
  filterOperatorsNumberKeysOnly=[],
  isCheckboxDisabled=null,
  isGenericAimsAPI=false,
  dependentApi=null,
  customSearchApi=null,
}) => {
  const [views, setViews] = useState([]);
  const [activeTab, setActiveTab] = useState({});
  const [activePage, setActivePage] = useState(0);
  const [pages, setPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [searchInitiated, setSearchInitiated] = useState(false);
  const [gridData, setGridData] = useState([]);
  const [pageSize, setPageSize] = useState(pagesizes[0]?.value);
  const [pageValues, setPageValues] = useState(pagesizes);
  const [records, setRecords] = useState(0);
  const [apiCount, setApiCount] = useState(1);
  const [totalGridData, setTotalGridData] = useState([]);
  const [removedExternalData, setRemovedExteranalData] = useState([]);
  const [isErrorAPI, setIsErrorAPI] = useState(false);
  const [exceptionMessage, setExceptionMessage] = useState(null);
  const [tabLinks, setTabLinks] = useState([]);
  const [removedTabs, setRemovedTabs] = useState([]);
  const [gridRemovedTab, setGridRemovedTab] = useState();
  const [isXIconVisible, setIsXIconVisible] = useState(false);
  const [search, setSearch] = useState("");
  const [dynamicFilterHandler, setDynamicFilterHandler] =
    useState(DynamicFilters);
  const [searchError, setSearchError] = useState("");
  const [defaultViewLoading, setDefaultLoading] = useState(false);
  const [sortedViews, setSortedViews] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [exportItems, setExportItems] = useState([]);
  const [exportFileName, setExportFileName] = useState("");
  const [previewLayout, setPreviewLayout] = useState(null);
  const [optionsLoading, setOptionsLoading] = useState(true);
  const [alreadyDisplayedSelectedIds, setAlreadyDisplayedSelectedIds] =
    useState([]);
  const [parentSelectedIds, setParentSelectedIds] = useState([]);
  const [isgridEmptySelected, setIsEmptySelected] = useState(false);
  const [searchInputDisable,setSearchInputDisable] = useState(false);
  // const [presentPageSelected, setPesentPageSelected] = useState([]);
  const { logger } = useContext(LoggerContext);
  const darkMode = useSelector((state) => state.darkMode);
  // const isMobile = mobileMode();
  let isViewUpdateActive =
    Boolean(JSON.parse(localStorage.getItem("CreateDefaultViewByAdmin"))) ||
    false;
  const gridRef = useRef(null);
  const gridStateRef = useRef(null);
  const csvRef = useRef(null);
  const isTabChange = useRef(false);
  const isTabChangeForAll = useRef(false);

  /**
   * Fetch views and set active view
   */
  useEffect(async () => {
    AddSpinner();
    setActivePage(0);
    setOptionsLoading(true);
    await getViews(logger, ModuleMenuCode)
      .then((res) => {
        if (res.statusCode === 200) {
          if (activeViewName) {
            const matchedView = _.find(res.data, { name: activeViewName });
            if (matchedView) {
              localStorage.setItem("DefaultViewId", matchedView.viewId);
            }
          }
          const allViews = _.map(res?.data, (ele) => {
            let viewDetails = ele?.viewDetails
              ? JSON.parse(ele?.viewDetails)
              : "";
            if (viewDetails && !_.isEmpty(viewDetails.columns)) {
              viewDetails.columns = _.map(viewDetails.columns, (vd) => ({
                ...vd,
                header: getLabel(vd?.header) || "",
                field: getLabel(vd?.field) || "",
              }));
            }
            return {
              ...ele,
              viewDetails: JSON.stringify(viewDetails),
            };
          });
          if (specificSubCategory?.length !== 0) {
            const filteredViews = _.filter(allViews, (view) =>
              _.includes(specificSubCategory, view.subcategory)
            );
            setViews(filteredViews);
            viewsOrderSetup(filteredViews);
          } else {
            setViews(allViews);
            viewsOrderSetup(allViews);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        viewsOrderSetup([]);
      })
      .finally(() => {
        RemoveSpinner();
        setOptionsLoading(false);
      });
  }, [ModuleMenuCode, refresh]);

  useEffect(() => {
    if (parentSelectedItems.length > 0) {
      setParentSelectedIds(parentSelectedItems);
    }
  }, [parentSelectedItems]);

  useEffect(() => {
    setIsEmptySelected(isEmptySelected);
    if (isEmptySelected) {
      setSelectedItems([]);
      setParentSelectedIds(parentSelectedItems);
    }
  }, [isEmptySelected]);

  /**
   * Set active tab and page when active tab changes
   */
  useEffect(() => {
    if (activeTab && Object.keys(activeTab).length > 0) {
      setSelectedItems([]);
      setActiveTabOnView(activeTab);
      checkSearch = false;
      setIsEmptySelected(false);
      setSearchInitiated(false);
      setActivePage(insertParentPage>1?insertParentPage:1);
      onTabChange && onTabChange(activeTab);
      activeTab.viewId &&
        localStorage.setItem("DefaultViewId", activeTab.viewId);
    }
  }, [activeTab]);

  /**
   * Fetch grid data when active page or page size changes
   */
  useEffect(() => {
    getActivePage && getActivePage(activePage);
    if (!isTabChange.current) {
      fetchData();
    } else {
      isTabChange.current = false;
    }
  }, [activePage, pageSize]);

  useEffect(() => {
    if (dynamicFilterHandler.length !== 0 && !optionsLoading) {
      if (activePage <= 1) {
        fetchData();
      } else {
        setActivePage(1);
      }
    }
  }, [dynamicFilterHandler]);

  useEffect(() => {
    if (!_.isEqual(dynamicFilterHandler, DynamicFilters)) {
      setApiCount(1)
      setDynamicFilterHandler(DynamicFilters);
      setTotalGridData([])
    }
  }, [DynamicFilters, dynamicFilterHandler]);

  useEffect(() => {
     ReFetchAPI && fetchData()
  }, [ReFetchAPI]);

  useEffect(() => {
    if (updateGridData?.length > 0) setGridData(updateGridData);
  }, [updateGridData]);

  /**
   * Update page values when records or page sizes change
   */
  useEffect(() => {
    if (pagesizes?.length > 0) {
      const updatedArray = pagesizes.map((item) => {
        if (item.label === "All") {
          return { ...item, value: records };
        }
        return item;
      });
      setPageValues(updatedArray);
    }
  }, [records]);

  /**
   * Set sorted views when views change
   */
  useEffect(() => {
    if (views?.length > 0) {
      viewsOrderSetup(views);
    }
  }, [views]);

  

  // async function fetchData() {
  //   setIsLoading(true);
  //   removedPresentTab = false;
  //   if (searchInitiated) {
  //     updateSearchDataToGrid(searchGridData);
  //     return;
  //   }

  //   if (notOdata && totalGridData.length > 0) {
  //     const presentPageData = totalGridData.slice(
  //       (activePage - 1) * pageSize,
  //       activePage * pageSize
  //     );
  //     setGridData(presentPageData);
  //     setIsLoading(false);
  //     return;
  //   }

  //   if (Number(pageSize) === pageValues[pageValues.length - 1]?.value) {
  //     const numPages = Math.ceil(records / maxPageSize);
  //     fetchAllRecords(numPages);
  //     return;
  //   }

  //   if (activePage < 1 || checkSearch) {
  //     setIsLoading(false);
  //     return;
  //   }

  //   let result = [];
  //   const isExpandTab = _.some(apiExpandValues, (item) =>
  //     activeTab?.urlExpand?.toLowerCase()?.includes(item?.toLowerCase())
  //   );
  //   let presentPageData = [];
  //   if (isExpandTab && totalGridData.length <= activePage * pageSize) {
  //     setApiCount((prev) => prev + 1);
  //     result = await fetchDataForPage(
  //       apiCount,
  //       pageSize,
  //       activeTab?.urlExpand,
  //       activeTab?.filters,
  //       "",
  //       true
  //     );
  //     setTotalGridData((prev) => {
  //       const expandData = [...prev, ...result];
  //       presentPageData = expandData.slice(
  //         (activePage - 1) * pageSize,
  //         activePage * pageSize
  //       );
  //       let totalPages = Math.ceil(expandData?.length / pageSize);
  //       setPages(totalPages);
  //       return expandData;
  //     });
  //   } else if (isExpandTab) {
  //     presentPageData = totalGridData.slice(
  //       (activePage - 1) * pageSize,
  //       activePage * pageSize
  //     );
  //     let totalPages = Math.ceil(totalGridData?.length / pageSize);
  //     if (totalPages !== pages) setPages(totalPages);
  //   } else {
  //     result = await fetchDataForPage(
  //       activePage,
  //       pageSize,
  //       activeTab?.urlExpand,
  //       activeTab?.filters,
  //       "",
  //       true
  //     );
  //   }

  //   if (
  //     parentInteractionFlag &&
  //     activePage === 1 &&
  //     parentSelectedIds?.length > 0 &&
  //     alreadyDisplayedSelectedIds.length !== parentSelectedIds.length
  //   ) {
  //     const selectedData = await fetchSelectedDataForPage(activeTab?.urlExpand);
  //     addingExternalData(
  //       isExpandTab ? presentPageData : result,
  //       selectedData,
  //       []
  //     );
  //     setIsLoading(false);
  //     return;
  //   } else if (
  //     parentInteractionFlag &&
  //     activePage > 1 &&
  //     removedExternalData.length > 0
  //   ) {
  //     addingExternalData(
  //       isExpandTab ? presentPageData : result,
  //       removedExternalData,
  //       alreadyDisplayedSelectedIds
  //     );
  //     setIsLoading(false);
  //     return;
  //   }
  //   if (notOdata) {
  //     setTotalGridData(result);
  //     const presentPageData = result.slice(
  //       (activePage - 1) * pageSize,
  //       activePage * pageSize
  //     );
  //     setGridData(presentPageData);
  //   } else {
  //     setGridData(isExpandTab ? presentPageData : result);
  //   }
  //   setIsLoading(false);
  // }

  const setActiveTabOnView = (activeViewInfo) => {
    let viewssortedData = _.cloneDeep(views);
    const checkremovedTab = removedTabs.find(
      (ele) => ele.viewId === gridRemovedTab?.viewId
    );
    const checkAddingTab = removedTabs.find(
      (ele) => ele.viewId === activeViewInfo.viewId
    );
    const filteredTablinks = _.differenceBy(tabLinks, removedTabs, "viewId");
    let record = viewssortedData?.slice(0, 10);
    if (record?.length > 0) {
      const presentRecord = record?.find(
        (items) => items?.viewId === activeViewInfo?.viewId
      );
      if (!presentRecord && !checkAddingTab) {
        const lastIndex = record.length - 1;
        if (lastIndex >= 0) {
          record[lastIndex] = activeViewInfo;
        }
      } else if (checkremovedTab && !checkAddingTab) {
        const checkTab = filteredTablinks?.find(
          (item) => item?.viewId !== checkremovedTab?.viewId
        );
        if (checkTab) {
          record = filteredTablinks?.filter(
            (item) => item?.viewId !== checkremovedTab?.viewId
          );
          activeViewInfo = record[0];
        }
      } else if (checkAddingTab) {
        record = [...filteredTablinks, activeViewInfo];
        setRemovedTabs((prev) => {
          const list = prev.filter(
            (ele) => ele.viewId !== activeViewInfo?.viewId
          );
          return [...list];
        });
      }
    }
    if (removedTabs.length > 0 && !checkAddingTab) {
      const comparator = (obj1, obj2) => obj1.name === obj2.name;
      record = _.differenceWith(record, removedTabs, comparator);
    }
    if (viewssortedData.length > 0) {
      const sortedRecords = sortItems(record, { systemFirst: false });
      setTabLinks(isMobile ? [activeViewInfo] : sortedRecords);
      // setActiveTab(activeViewInfo)
      viewssortedData?.forEach((view) => {
        if (view?.name === activeViewInfo?.name) {
          view.isActiveTab = true;
        } else {
          view.isActiveTab = false;
        }
      });
      setSortedViews(viewssortedData);
    }
    // if (activeViewInfo !== null && activeViewInfo?.viewDetails) {
    //   gridRefer?.current?.loadLayout(
    //     JSON.stringify(activeViewInfo?.viewDetails)
    //   );
    // }
  };

  /**
   * Set up views order
   *
   * @param {Array} allviews - Array of all views
   */
  const viewsOrderSetup = (allviews) => {
    if (allviews?.length > 0) {
      setSearchError("");
      setIsXIconVisible(false);
      setSearch("");  
      const sortedData = sortItems(allviews, { systemFirst: false });
      const defaultView = parentDefaultViewId
        ? sortedData.find((ele) => ele.viewId === parentDefaultViewId)
        : parentInteractionFlag && activeViewName ?  _.find(sortedData, { name: activeViewName }) : sortedData[0];
      const defaultViewId = localStorage.getItem("DefaultViewId");
      const defaultTab = sortedData?.find(
        (ele) => ele.viewId === defaultViewId
      );
      const currentRecords = sortedData.slice(0, 10);
      const record = sortedData.slice(0, 10);

      if (currentRecords.length > 0) {
        const presentRecord = currentRecords?.find(
          (items) => items?.viewId === defaultViewId
        );
        if (!presentRecord) {
          const lastIndex = record.length - 1;
          if (lastIndex >= 0) {
            record[lastIndex] = defaultTab;
          }
        }
      }
      setSortedViews(sortedData);
      if (defaultTab && Object.keys(defaultTab).length > 0) {
        setActiveTabOnView(defaultTab);
        setActiveTab(defaultTab);
        setTabLinks(isMobile ? [defaultTab] : record);
        return;
      }
      setTabLinks(isMobile ? [sortedData[0]] : currentRecords);
      setActiveTab(defaultView);
    } else {
      const checkdata = [
        {
          name: SecretCodeForRoutes[ModuleMenuCode],
          runwayModuleId: ModuleMenuCode,
        },
      ];
      setActiveTab(checkdata[0]);
      setTabLinks(checkdata);
    }
  };

  /**
   * Remove duplicates from an array
   *
   * @param {Array} main - Main array
   * @param {Array} toAdd - Array to add
   * @returns {Array} - Array with duplicates removed
   */
  const removeDuplicates = (
    main,
    toAdd,
    parentSelected,
    alreadyDisplayedSelectedIds
  ) => {
    const selectedIdsSet = new Set(parentSelected);
    const displayedSelectedIdsSet = new Set(alreadyDisplayedSelectedIds);

    // Combine main and toAdd, prioritizing toAdd
    const combined = _.unionBy(
      toAdd,
      main,
      (item) => item[HeaderKeyMappings[parentPrimaryKey]]
    );

    // Filter the combined array
    return combined.filter((item) => {
      const itemId = item[HeaderKeyMappings[parentPrimaryKey]];
      return (
        !selectedIdsSet.has(itemId) || !displayedSelectedIdsSet.has(itemId)
      );
    });
  };
  /**
   * Add external data to the grid data
   *
   * @param {Array} main - Main array
   * @param {Array} toAdd - Array to add
   */
  // const addingExternalData = (main, toAdd, alreadyDisplayedSelectedIds) => {
  //   // Combine main with toAdd (which could be selectedData or removedExternalData)
  //   const combinedData = _.unionBy(toAdd, main, item => item[HeaderKeyMappings[parentPrimaryKey]]);

  //   const uniqueData = removeDuplicates(combinedData, [], parentSelectedItems, alreadyDisplayedSelectedIds);
  //   const trimmedDataset = uniqueData.slice(0, Number(pageSize));
  //   setGridData(trimmedDataset);
  //   setIsLoading(false);
  //   const excessRecords = uniqueData.slice(Number(pageSize), uniqueData.length);
  //   setRemovedExteranalData(excessRecords);

  //   // Update alreadyDisplayedSelectedIds with only parentSelectedItems that are in trimmedDataset
  //   const newDisplayedSelectedIds = trimmedDataset
  //     .filter(item => parentSelectedItems.includes(item[HeaderKeyMappings[parentPrimaryKey]]))
  //     .map(item => item[HeaderKeyMappings[parentPrimaryKey]]);

  //   setAlreadyDisplayedSelectedIds(prevIds => _.uniq([...prevIds, ...newDisplayedSelectedIds]));

  //   return trimmedDataset;
  // };

  const addingExternalData = (main, toAdd) => {
    const uniqueData = removeDuplicates(main, toAdd);
    const trimmedDataset = uniqueData.slice(0, Number(pageSize));
    setGridData(trimmedDataset);
    const newDisplayedSelectedIds = trimmedDataset
      .filter((item) =>
        parentSelectedIds.includes(item[HeaderKeyMappings[parentPrimaryKey]])
      )
      .map((item) => item[HeaderKeyMappings[parentPrimaryKey]]);
    setAlreadyDisplayedSelectedIds((prevIds) =>
      _.uniq([...prevIds, ...newDisplayedSelectedIds])
    );
    setIsLoading(false);
    setParentSelectedIds((prevIds) =>
      _.difference(prevIds, newDisplayedSelectedIds)
    );
    const excessRecords = uniqueData.slice(
      Number(pageSize),
      uniqueData?.length
    );
    setRemovedExteranalData(excessRecords);
    return trimmedDataset;
  };

  const getDataTypeWithAPIKey = (key) => {
    // Find the matching key in HeaderKeyMappings
    const matchingKey = Object.keys(HeaderKeyMappings).find(
      (mappingKey) => mappingKey === key || mappingKey.endsWith(`__${key}`)
    );

    if (!matchingKey) {
      return null;
    }

    const mappedValue = HeaderKeyMappings[matchingKey];

    // Find the matching configuration in gridColumns
    const matchingConfig = gridColumns.find(
      (config) => config.name === mappedValue
    );

    return matchingConfig ? matchingConfig.dataType : null;
  };

  function parseTimeZone(timeZoneString) {
    const matches = timeZoneString.match(/\((UTC[+-]\d{2}:\d{2})\)\s*(.*)/);
    if (matches && matches[1]) {
      return matches[1].replace('UTC', '');
    }
    return '+00:00'; // Default to UTC if parsing fails
  }

  const gridDateFormatFromUiToApi=(value)=>{
    let clientTimeZone=localStorage.getItem('defaultTimeZone');
    const offset = moment().tz(clientTimeZone).format('Z');
    const inputTime = moment(value, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').toDate();
    const convertedTime = moment(inputTime).utcOffset(offset).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    return convertedTime;
  }

  function convertToStandardFormat(dateTimeString, userSettings) {
    const { date_format, time_format, time_zone } = userSettings;
    const isDateOnly = !dateTimeString.includes(':');
    const timeZoneOffset = parseTimeZone(time_zone);
  
    let inputFormat = date_format;
    if (!isDateOnly) {
      inputFormat += time_format === "12 Hours AM/PM" ? ' hh:mm:ss A' : ' HH:mm:ss';
    }
  
    // Parse the input string
    let parsedDateTime = moment(dateTimeString, inputFormat);
  
    if (!parsedDateTime.isValid()) {
      return dateTimeString;
    }
  
    if (isDateOnly) {
      // For date-only, return in YYYY-MM-DD format without timezone conversion
      return parsedDateTime.format('YYYY-MM-DD');
    } else {
      // Change the format to ISO 8601
      let isoString = parsedDateTime.format('YYYY-MM-DDTHH:mm:ss');
    
      // Add the timezone offset
      let dateTimeWithOffset = `${isoString}${timeZoneOffset}`;
    
      // Parse the datetime with offset
      let finalDateTime = moment(dateTimeWithOffset).toDate();
      return gridDateFormatFromUiToApi(finalDateTime);
  }
  }

  const checkIsExpandTab = () => {
    return _.some(apiExpandValues, (item) =>
      activeTab?.urlExpand?.toLowerCase()?.includes(item?.toLowerCase())
    );
  };

  const formatValue = (value, isNumberKey) => (value === "null" || isNumberKey) ? value : `'${value}'`;

  
  function formatDateValue(value, dataType) {
    if (dataType?.toLowerCase() === "date" || dataType?.toLowerCase() === "datetime") {
      const userSettings = GetSystemSettings() || {};
      return convertToStandardFormat(value, userSettings);
    }
    return value;
  }

  const createCondition = (field, operator, valueFormat) => {
    switch (operator) {
        case "contains":
        case "startswith":
        case "endswith":
            return `${operator}(${field}, ${valueFormat})`;
        case "substringof":
            return `${operator}(${valueFormat}, ${field})`;
        default:
            return `${field} ${operator} ${valueFormat}`;
    }
};

  const createFilterExpression = (key, operator, valueFormat) => {
    const customFilterFields = customFilterValues[key];

    if (customFilterFields && Array.isArray(customFilterFields)) {
        const conditions = customFilterFields?.map(field => 
            createCondition(field, operator, valueFormat)
        );
        // If there's more than one condition, wrap them in parentheses
      if (conditions.length > 1) {
        return `(${conditions.join(" or ")})`;
      } else {
        return conditions[0]; // If there's only one condition, no need for grouping
      }
    }
    
    // Default case if it's not a custom filter
    return createCondition(key, operator, valueFormat);
};
  
  const createNestedFilter = (entities, filterKey, operator, valueFormate, isExpandTab) => {
    let fullPath = [...entities, filterKey].join('/');
    let filter = createFilterExpression(isExpandTab ? `{item}/${filterKey}` : fullPath, operator, valueFormate);

    if (isExpandTab) {
        for (let i = entities.length - 1; i >= 0; i--) {
          const newAlias = `x${i}`;
          filter = `${entities[i]}/any(${newAlias}:${filter.replace(new RegExp('{item}', 'g'), newAlias)})`;
          if (i !== 0) {
            filter = `{item}/${filter}`;
          }
        }
    }
    return filter;
  };
  
  const checkExpandValues = (key, operator, value, header) => {
      const dataType = getDataTypeWithAPIKey(key) || "string";
      const isNumberOrDateType = _.includes(["number", "date", "datetime", "boolean"], dataType?.toLowerCase());
      const isNumberKey = isNumberOrDateType || _.includes(filteringNumberAndGuidKeys, key);
  
      // Apply value transformations
      if (filterValueTransformations[key]) {
        Object.entries(filterValueTransformations[key]).forEach(([originalValue, newValue]) => {
          if (value.includes(originalValue)) {
              value = value.replace(originalValue, newValue);
          }
        });
      }
      // Update value if it matches a dynamic filter
      const dynamicFilter = DynamicFilters?.find(item => item?.label === value);
      if (dynamicFilter) value = dynamicFilter.value;
  
      value = value === "null" ? value : formatDateValue(value, dataType);
      const valueFormate = formatValue(value, isNumberKey);
  
      const isExpandTab = checkIsExpandTab();
      const [matchingKey] = Object.entries(HeaderKeyMappings).find(([, val]) => val === header) || [];
      const parts = matchingKey?.includes('__') ? matchingKey.split('__') : [];
  
      if (parts?.length > 0) {
          const filterKey = parts.pop();
          const entities = parts;
          const isDirectPropertyPath = directPropertyPath?.includes(filterKey);
  
          if (isExpandTab && !isDirectPropertyPath) {
              return createNestedFilter(entities, filterKey, operator, valueFormate, true);
          } else {
              return createNestedFilter(entities, filterKey, operator, valueFormate, false);
          }
      }
  
      if (key && value) {
          return createFilterExpression(key, operator, valueFormate);
      }
  
      return undefined;
  };

  /**
   * Check expand values for filtering
   *
   * @param {string} key - Key
   * @param {string} operator - Operator
   * @param {string} value - Value
   * @returns {string} - Expand value string
   */
//   const checkExpandValues = (key, operator, value, header) => {
//     // Use the getDataTypeWithAPIKey function to get the data type
//     const dataType = getDataTypeWithAPIKey(key);

//     // Check if the dataType is Number, Date, or DateTime
//     const isNumberOrDateType = _.includes(
//       ["number", "date", "datetime", "boolean"],
//       dataType?.toLowerCase()
//     );
//     const isNumberKey =
//       isNumberOrDateType || _.includes(filteringNumberAndGuidKeys, key);
//     DynamicFilters?.forEach((item) => {
//       if (item?.label === value) {
//         value = item?.value;
//         return;
//       }
//     });
//     if (
//       dataType?.toLowerCase() === "date" ||
//       dataType?.toLowerCase() === "datetime"
//     ) {
//       // Check if the date includes time
//       const hasTime = /\d{1,2}:\d{2}/.test(value);

//       if (!hasTime) {
//         const userSettingsData = GetSystemSettings() || {};
//         const dateFormat = userSettingsData?.date_format || "MM/dd/YYYY";
//         // If it doesn't have time, convert to standard format
//         const standardDate = convertToStandardFormat(
//           value,
//           dateFormat?.toLowerCase()
//         );
//         value = standardDate;
//       }
//     }
//     const valueFormate = isNumberKey ? value : `'${value}'`;
//     const isExpandTab = checkIsExpandTab();
//     const [matchingKey] =
//       _.find(_.entries(HeaderKeyMappings), ([key, val]) => val === header) ||
//       [];
//     const parts = matchingKey?.includes('__') ? matchingKey.split('__') : [];
//     if (parts?.length > 0) {
//       const filterKey = parts?.pop(); // Last part is always the filter key
//       const entities = parts; // Remaining parts are the entities
//       const isDirectPropertyPath = directPropertyPath?.includes(filterKey);
// if (isExpandTab && !isDirectPropertyPath) {
//   let filter = `item/${filterKey} ${operator} ${valueFormate}`;
//   if (
//     operator === "contains" ||
//     operator === "startswith" ||
//     operator === "endswith"
//   ) {
//     filter = `${operator}(item/${filterKey}, ${valueFormate})`;
//   } else if (operator === "substringof") {
//     filter = `${operator}(${valueFormate}, item/${filterKey})`;
//   }

//   // Wrap the filter in 'any' expressions for each nested level
//   for (let i = entities.length - 1; i >= 0; i--) {
//     filter = `${entities[i]}/any(item:${filter})`;
//   }

//   return filter;
// } else {
//   let filter = `${filterKey} ${operator} ${valueFormate}`;
//   if (
//     operator === "contains" ||
//     operator === "startswith" ||
//     operator === "endswith"
//   ) {
//     filter = `${operator}(${filterKey}, ${valueFormate})`;
//   } else if (operator === "substringof") {
//     filter = `${operator}(${valueFormate}, ${filterKey})`;
//   }

//   // Wrap the filter in 'any' expressions for each nested level
//   for (let i = entities.length - 1; i >= 0; i--) {
//     filter = `${entities[i]}/${filter}`;
//   }

//   return filter;
// }
// }

//     if (
//       operator === "contains" ||
//       operator === "startswith" ||
//       operator === "endswith"
//     ) {
//       return `${operator}(${key}, ${valueFormate})`;
//     } else if (operator === "substringof") {
//       return `${operator}(${valueFormate}, ${key})`;
//     }
//     if (key && value) {
//       return (
//         key +
//         " " +
//         operator +
//         " " +
//         (value === "null" || isNumberKey ? value : "'" + value + "'")
//       );
//     } else {
//       return;
//     }
//   };

  /**
   * Check if parentheses are balanced in a string
   *
   * @param {string} str - Input string
   * @returns {boolean} - True if parentheses are balanced, false otherwise
   */
  // function areParenthesesBalanced(str) {
  //   const stack = [];

  //   let hasContent = false;

  //   for (let char of str) {
  //     if (char === "(") {
  //       stack.push(char);
  //       hasContent = false;
  //     } else if (char === ")") {
  //       if (stack.length === 0 || !hasContent) {
  //         return false;
  //       }
  //       stack.pop();
  //     } else if (char.trim() !== "") {
  //       hasContent = true;
  //     }
  //   }

  //   return stack.length === 0 && hasContent;
  // }

  /**
   * Convert conditions to query string
   *
   * @param {Array} conditions - Array of conditions
   * @returns {string} - Query string
   */

  const convertToQueryString = (conditions) => {
    let result = [];
    let groupLevel = 0;
    let lastValidExpression = false;
    let pendingCondition = null;
    let lastGroupClosed = false;

    const addToResult = (item) => {
      if (item !== null && item !== undefined) {
        if (result.length > 0 && item !== ")" && result[result.length - 1] !== "(") {
          result.push(" ");
        }
        result.push(item);
      }
    };
    for (let i = 0; i < conditions.length; i++) {
      const condition = conditions[i];
      switch (condition.type) {
        case "Expression":
          const value = checkExpandValues(
            condition.key,
            condition.operator,
            condition.value,
            condition?.header
          );
          if (value !== null && value !== undefined) {
            if (pendingCondition && (lastValidExpression || lastGroupClosed)) {
              addToResult(pendingCondition);
            }
            addToResult(value);
            lastValidExpression = true;
            lastGroupClosed = false;
            pendingCondition = null;
          } else {
            lastValidExpression = false;
          }
          break;
        case "Condition":
          if (lastValidExpression || lastGroupClosed) {
            pendingCondition = condition.condition.toLowerCase();
          }
          break;
        case "Group":
          if (condition.value === "(") {
            if (pendingCondition && (lastValidExpression || lastGroupClosed)) {
              addToResult(pendingCondition);
              pendingCondition = null;
            }
            addToResult("(");
            groupLevel++;
            lastGroupClosed = false;
            lastValidExpression = false;
          } else if (condition.value === ")") {
            if (groupLevel > 0) {
              // Only add closing parenthesis if there's content inside the group
              if (result[result.length - 1] !== "(") {
                addToResult(")");
                groupLevel--;
                lastValidExpression = false;
                lastGroupClosed = true;
              } else {
                // Remove the opening parenthesis if the group is empty
                result.pop();
                groupLevel--;
              }
            }
          }
          break;
        default:
          console.warn(`Unexpected condition type: ${condition.type}`);
          break;
      }
    }
  
    // Close any remaining open groups
    while (groupLevel > 0) {
      addToResult(")");
      groupLevel--;
    }
  
    // Remove redundant conditions
    const finalResult = result.join('')
      .replace(/\(\s*\)/g, '') // Remove empty parentheses
      .replace(/\s+(and|or)\s+\)/g, ')') // Remove trailing conditions before closing parenthesis
      .replace(/\(\s+(and|or)\s+/g, '(') // Remove leading conditions after opening parenthesis
      .replace(/\s+(and|or)\s+$/g, '') // Remove trailing conditions at the end
      .trim();
  
    return finalResult;
  };

  /**
   * Handle API error
   *
   * @param {*} err - Error object
   */
  const handleError = (err) => {
    setIsErrorAPI(true);
    setExceptionMessage(err);
  };

  async function fetchAllDataWithNextLinks(initialUrl) {
    let allData = [];
    let nextLink = initialUrl;
    let count = 0;
  
    while (nextLink) {
      try {
        let response;
        if(isGenericAimsAPI){
          response = await GetRequest(genericAimsApi, logger, null, false, {
            "AIMS360APIURL": nextLink, // ✅ Custom header
            "Environment": AppEnv,
          });
        } else {
          response = await GetRequest(nextLink, logger);
        }
        
        if (response.status !== 200 && response.status !== 204) {
          console.error(`API request failed with status: ${response.status}`);
          handleError(response);
          break;
        }
  
        const pageData = response?.data?.value || response?.data?.data || response?.data;
        allData = allData.concat(pageData);
  
        nextLink = response?.data["@odata.nextLink"];
        count = response?.data["@odata.count"];
      } catch (error) {
        console.error("An error occurred while fetching data:" + error);
        handleError(error);
        setSearchInputDisable(false)
        break;
      }
    }
  
    setRecords(allData.length);
    const allValue = pageValues?.find(
      (size) => size?.label?.toLowerCase() === "all"
    )?.value;

    if (pageSize === allValue) {
      setPages(1); // Since we're fetching all data, we can consider it as one page
    } else {
      let totalPages = Math.ceil(count / pageSize);
      setPages(totalPages);
    }

    let renamedData = flattenArrayOfObjects(
      allData,
      HeaderKeyMappings,
      modifyColumnData,
      columnsToModify,
      nestedArrayToModify
    );
    if (customizeGridData) {
      renamedData = customizeGridData(renamedData);
    }
    getResponseRenamed && getResponseRenamed(renamedData);
    setSearchInputDisable(false)
    return renamedData;
  }

  function convertQueryStringForSearchAll(value) {
    return `pickTicket eq '${value}' or order eq '${value}' or customerPurchaseOrder eq '${value}'`;
  }
  
  /**
   * Fetch data for a specific page
   *
   * @param {number} page - Page number
   * @param {number} pageSizeApi - Page size for API request
   * @param {string} expand - Expand string
   * @param {string} filters - Filters string
   * @param {string} [searchFilterString=""] - Search filter string
   * @param {boolean} [getRecords=false] - Flag to get records count
   * @param {boolean} [callNextLinks=false] - Flag to call next links
   * @param {boolean} [searchAll=false] - Flag to search all
   * @returns {Promise<Array>} - Promise resolving to an array of data
   */
  async function fetchDataForPage(
    page,
    pageSizeApi,
    expand,
    filters,
    searchFilterString = "",
    getRecords = false,
    callNextLinks = false,
    searchAll = false
  ) {
    try {
      setSearchInputDisable(true)
      let queryString = filters
        ? convertToQueryString(JSON.parse(filters))
        : "";

      if (searchFilterString) {
        queryString += queryString
          ? `and (${searchFilterString})`
          : searchFilterString;
      }
      
      const mainURL = ModuleMenuCode ? gridBaseUrl[ModuleMenuCode] : baseURL;

      const url = generateUrl(
        mainURL,
        pageSizeApi,
        page,
        expand,
        queryString,
        urlEndPoint || activeTab?.urlEndPoint, 
        additionalOdataParamsForAPI,
        isPageSize,
        isUrlDoubleEncode,
        isGenericAimsAPI
      );

      setIsErrorAPI(false);
      if (callNextLinks) {
        return await fetchAllDataWithNextLinks(url);
      }

      let response;

      if(searchAll && search){
        response = await customSearchApi?.(search)
      } else if(isGenericAimsAPI){
        response = await GetRequest(genericAimsApi, logger, null, false, {
          "AIMS360APIURL": url, // ✅ Custom header
          "Environment": AppEnv,
        });
      } else {
        response = await GetRequest(url, logger);
      }
      
      if(dependentApi){
        response = await dependentApi(response);
      }

      getResponse && getResponse(response);
      const pageData =
        response?.data?.value || response?.data?.data || response?.data;
      if (response.status !== 200 && response.status !== 204) {
        console.error(`API request failed with status: ${response.status}`);
        handleError(response);
        setSearchInputDisable(false)
        return [];
      }

      if (notOdata) {
        getRecords && setRecords(pageData?.length);
        let totalPages = Math.ceil(pageData?.length / pageSize);
        setPages(totalPages);
      } else {
        getRecords && setRecords(response?.data["@odata.count"]);
        let totalPages = Math.ceil(response?.data["@odata.count"] / pageSize);
        setPages(totalPages);
      }

      let renamedData = flattenArrayOfObjects(
        pageData,
        HeaderKeyMappings,
        modifyColumnData,
        columnsToModify,
        nestedArrayToModify
      );
      if (customizeGridData) {
        renamedData = customizeGridData(renamedData);
      }
      getResponseRenamed && getResponseRenamed(renamedData);
      setSearchInputDisable(false)
      return renamedData;
    } catch (error) {
      console.error("An unexpected error occurred:" + error);
      handleError(error);
      setSearchInputDisable(false)
      return [];
    }
  }

  /**
   * Fetch all records
   *
   * @param {number} renderApi - Number of API requests to make
   * @returns {Promise<Array>} - Promise resolving to an array of all records
   */
  const fetchAllRecords = async (renderApi, filter) => {
    setIsLoading(true);
    let fetchPromises = [];
    for (let i = 1; i <= renderApi; i++) {
      if (isTabChangeForAll.current) {
        break;
      }
      fetchPromises.push(
        await fetchDataForPage(
          i,
          maxPageSize,
          activeTab?.urlExpand,
          filter,
          "",
          true
        )
      );
    }
    if (isTabChangeForAll.current) {
      isTabChangeForAll.current = false;
      return [];
    }
    // const result = await Promise.all(fetchPromises);
    setActivePage(1);
    setPages(1);
    setGridData(fetchPromises.flat());
    getResponseRenamed && getResponseRenamed(fetchPromises.flat());
    setIsLoading(false);
    return fetchPromises.flat();
  };

  /**
   * Fetch selected data for a specific page
   *
   * @param {string} expand - Expand string
   * @returns {Promise<Array>} - Promise resolving to an array of selected data
   */
  async function fetchSelectedDataForPage(expand) {
    const uniqueItems = _.uniq(parentSelectedItems);
    const batchSize = 40;
    const batches = _.chunk(uniqueItems, batchSize);
    let selectedData = [];

    for (const batch of batches) {
      try {
        const dataType = getDataTypeWithAPIKey(parentPrimaryKey);

        // Check if the dataType is Number, Date, or DateTime
        const isNumberOrDateType = _.includes(
          ["number", "date", "datetime", "boolean"],
          dataType?.toLowerCase()
        );
        const isWithOutQuotes =
          isNumberOrDateType ||
          _.includes(filteringNumberAndGuidKeys, parentPrimaryKey);
        const batchString = isWithOutQuotes
          ? batch.join(",")
          : `"${batch.join('","')}"`;
        const filterString = `${parentPrimaryKey} in (${batchString})`;
        const mainURL = ModuleMenuCode ? gridBaseUrl[ModuleMenuCode] : baseURL;
        const url = generateUrl(
          mainURL,
          maxPageSize,
          1,
          expand,
          filterString,
          urlEndPoint || activeTab?.urlEndPoint,
          additionalOdataParamsForAPI,
          isPageSize,
          isUrlDoubleEncode,
          isGenericAimsAPI
        );

        let response;

        if(isGenericAimsAPI){
          response = await GetRequest(genericAimsApi, logger, null, false, {
            "AIMS360APIURL": url, // ✅ Custom header
            "Environment": AppEnv,
          });
        } else {
          response = await GetRequest(url, logger);
        }
  
        const batchData = response?.data?.value;
        if (batchData) {
          selectedData = [...selectedData, ...batchData];
        } else {
          console.warn(`No data returned for batch: ${batch}`);
        }
      } catch (error) {
        console.error(`Error fetching data for batch: ${batch}`, error);
        setIsLoading(false);
        // You might want to implement retry logic here or handle the error in a specific way
      }
    }

    try {
      const renamedData = flattenArrayOfObjects(
        selectedData,
        HeaderKeyMappings,
        modifyColumnData,
        columnsToModify,
        nestedArrayToModify
      );
      return renamedData;
    } catch (error) {
      console.error("Error in flattenArrayOfObjects:", error);
      setIsLoading(false);
      return []; // Return an empty array if flattening fails
    }
  }

  /**
   * Handle tab close event
   *
   * @param {string} value - Tab value
   */
  const handleTabClose = (value) => {
    let updatedTabs;
    setTabLinks((prevTabs) => {
      const presentTab = prevTabs?.find((ele) => ele.name === value);
      setGridRemovedTab(presentTab);
      setRemovedTabs((prev) => [...prev, presentTab]);
      updatedTabs = prevTabs.filter((tab) => tab.name !== value);
      removedPresentTab = true;
      setActiveTab(updatedTabs[0]);
      return updatedTabs.length > 0 ? updatedTabs : prevTabs;
    });
  };

  /**
   * Get updated views
   */
  const getUpdatedViews = async (isPageActive = false, spinner = false) => {
    try {
      const res = await getViews(logger, ModuleMenuCode);
      if (res.statusCode === 200) {
        const updatedData = _.map(res?.data, (ele) => {
          let viewDetails = ele?.viewDetails
            ? JSON.parse(ele?.viewDetails)
            : "";
          if (viewDetails && !_.isEmpty(viewDetails.columns)) {
            viewDetails.columns = _.map(viewDetails.columns, (vd) => ({
              ...vd,
              header: getLabel(vd?.header) || "",
              field: getLabel(vd?.field) || "",
            }));
          }
          return {
            ...ele,
            viewDetails: JSON.stringify(viewDetails),
          };
        });
        if (specificSubCategory?.length !== 0) {
          const filteredViews = _.filter(updatedData, (view) =>
            _.includes(specificSubCategory, view.subcategory)
          );
          setViews(filteredViews);
        } else {
          setViews(updatedData);
        }
        if (isPageActive) {
          setActivePage(0);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onTabSwitch = async (isPageActive = false) => {
    setViews([]);
    if (isPageActive) {
      setActivePage(0);
    }
  }

  /**
   * Search data
   */
  const searchData = async () => {
    if (!search) return;
    AddSpinner();
    try {
      const searchFilterString = searchFilter.replace(/{search}/g, search);
      const baseFilters = previewLayout?.isFilter ? previewLayout?.filters : activeTab?.filters;
      const result = await fetchDataForPage(
        1,
        pageSize,
        activeTab?.urlExpand,
        baseFilters,
        searchFilterString,
        true,
        false,
        searchAllMode
      );
      setSearchInitiated(true);
      setSearchError("");
      setActivePage(1);
      setGridData(result);
      checkSearch = true;
    } catch (error) {
      console.error("Search failed:", error);
      handleError(error);
    } finally {
      RemoveSpinner();
    }
  };
  /**
   * Handle click on X icon in search bar
   */
  const onClickXIcon = async () => {
    setSearch("");
    setSearchError("");
    setIsXIconVisible(false);
    setIsLoading(true);
    setSearchInitiated(false);
    checkSearch = false;
    const allValue = pageValues?.find(
      (size) => size?.label?.toLowerCase() === "all"
    )?.value;
    if (Number(pageSize) === allValue) {
      const numPages = Math.ceil(records / maxPageSize);
      fetchAllRecords(numPages, previewLayout?.isFilter ? previewLayout?.filters : activeTab?.filters);
      return;
    }
    const result = await fetchDataForPage(
      1,
      pageSize,
      activeTab?.urlExpand,
      previewLayout?.isFilter ? previewLayout?.filters : activeTab?.filters,
      "",
      true
    );
    setGridData(result);
    setIsLoading(false);
    setActivePage(1);
  };

  const checkDataTypeWithKey = (data, keyToCheck, dataTypeToCheck) => {
    return data.some(obj => 
      _.get(obj, "name") === keyToCheck && 
      obj.dataType.toLowerCase() === dataTypeToCheck.toLowerCase()
    );
  };

  /**
   * Extract data based on selected columns
   *
   * @param {Array} data - Input data
   * @returns {Array} - Extracted data
   */
  const extractData = (data) => {
    const SelectedCols = [];
    const viewDetails = gridStateRef?.current?.getStateAsString([])
      ? JSON.parse(gridStateRef?.current?.getStateAsString([]))
      : "";
    if (viewDetails !== null && viewDetails?.columns?.length > 0) {
      viewDetails.columns.forEach((vd) => {
        if (!vd.hidden) {
          SelectedCols.push({ label: vd.header, value: vd.header });
        }
      });

      const SelectedColsArray = SelectedCols.map((obj) => {
        const filteredObject = {};
        Object.keys(obj).forEach((key) => {
          if (!keysToRemove.includes(key)) {
            filteredObject[key] = obj[key];
          }
        });
        return filteredObject;
      });
      const matchingValues = SelectedColsArray.map((item) => item.value);
      const result = data.map((item) => {
        const orderedObject = {};
        matchingValues.forEach((key) => {
          orderedObject[key] = item[key];
        });
        return orderedObject;
      });
      const updatedResult = _.map(result, (obj) => {
        return _.mapValues(obj, (value, key) => {
          if (previewDates.includes(key) || checkDataTypeWithKey(gridColumns, key, 'DateTime')) {
            return dateFormatFromApitoUi(
              value, true
            );
          }
          if (typeof value === 'string' && (value.includes(',') || value.includes('"'))) {
            value = `${value.replace(/"/g, '""')}`;
          }
          return value;
        });
      });
      return updatedResult;
    }
  };

  /**
   * Export data to CSV
   */
  const exportData = () => {
    const now = new Date();
    const fileName = `${activeTab?.name}_${now.getFullYear()}${(
      now.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}${now.getDate().toString().padStart(2, "0")}${now
      .getHours()
      .toString()
      .padStart(2, "0")}${now.getMinutes().toString().padStart(2, "0")}${now
      .getSeconds()
      .toString()
      .padStart(2, "0")}.csv`;
    setExportFileName(fileName);
    const exportData = extractData(
      selectedItems.length > 0 ? selectedItems : gridData
    );
    const finalExport =
      exportOrderBy.length > 0
        ? _.orderBy(exportData, exportOrderBy, ["asc", "asc"])
        : exportData;
    setExportItems(finalExport);
    setTimeout(() => {
      csvRef?.current.click();
    }, 0);
    setTimeout(() => {
      setExportFileName("");
      setExportItems([]);
    }, 1000);
  };

  /**
   * Handle selected tabs
   *
   * @param {Object} selectedTab - Selected tab
   */
  const getSelectedTabs = (selectedTab) => {
    setActiveTab(selectedTab);
    setActivePage(0);
    setTotalGridData([]);
    setApiCount(1);
    setSearch("");
    setSearchError("");
    setSearchInitiated(false);
    setGridData([]);
  };

  /**
   * Create default view
   */
  const createDefaultView = () => {
    setDefaultLoading(true);
    const layout = gridStateRef?.current?.getStateAsString([]);
    const payLoad = {
      name: DefaultViewName,
      category: DefaultCategory,
      viewDetails: layout,
      filters: DefaultFilters,
      urlExpand: DefaultUrlExpand,
      type: "System",
      runwayModuleId: ModuleMenuCode,
      urlEndPoint: urlEndPoint,
      subcategory: viewSubcategory,
    };

    postView(payLoad, logger)
      .then((res) => {
        getUpdatedViews();
        localStorage.removeItem("CreateDefaultViewByAdmin");
        console.log("created default view");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setDefaultLoading(false);
        isViewUpdateActive = false;
      });
  };


  const fetchData = useCallback(async () => {
    setIsLoading(true);
    removedPresentTab = false;
    if (searchInitiated) {
      const searchFilterString = searchFilter.replace(/{search}/g, search);
      const baseFilters = previewLayout?.isFilter ? previewLayout?.filters : activeTab?.filters;
  
      const result = await fetchDataForPage(
        activePage,
        pageSize,
        activeTab?.urlExpand,
        baseFilters,
        searchFilterString,
        false,
        false,
        searchAllMode
      );
      
      setGridData(result);
      setIsLoading(false);
      return;
    }

    if (notOdata && totalGridData.length > 0) {
      const presentPageData = totalGridData.slice(
        (activePage - 1) * pageSize,
        activePage * pageSize
      );
      setGridData(presentPageData);
      isTabChangeForAll.current = false;
      setIsLoading(false);
      return;
    }
  
    if (Number(pageSize) === pageValues[pageValues.length - 1]?.value) {
      const numPages = Math.ceil(records / maxPageSize);
      fetchAllRecords(numPages, previewLayout?.isFilter ? previewLayout?.filters : activeTab?.filters);
      return;
    }
  
    if (activePage < 1 || checkSearch) {
      setIsLoading(false);
      return;
    }
  
    let result = [];
    const isExpandTab = _.some(apiExpandValues, (item) =>
      activeTab?.urlExpand?.toLowerCase()?.includes(item?.toLowerCase())
    );
    let presentPageData = [];
    if (isCallNextLinksForAllData && totalGridData.length <= 0) {
      const allData = await fetchDataForPage(
        1,
        allDataForRunwayAPIs ? 0 : maxPageSize,
        activeTab?.urlExpand,
        previewLayout?.isFilter ? previewLayout?.filters : activeTab?.filters,
        "",
        true,
        true
      );
      setTotalGridData(allData);
      presentPageData = allData.slice(
        (activePage - 1) * pageSize,
        activePage * pageSize
      );
    } else if (isCallNextLinksForAllData && totalGridData.length > 0) {
      presentPageData = totalGridData.slice(
        (activePage - 1) * pageSize,
        activePage * pageSize
      );
    } else if (isExpandTab && totalGridData.length <= activePage * pageSize) {
      setApiCount((prev) => prev + 1);
      result = await fetchDataForPage(
        apiCount,
        pageSize,
        activeTab?.urlExpand,
        previewLayout?.isFilter ? previewLayout?.filters : activeTab?.filters,
        "",
        true
      );
      setTotalGridData((prev) => {
        const expandData = [...prev, ...result];
        presentPageData = expandData.slice(
          (activePage - 1) * pageSize,
          activePage * pageSize
        );
        let totalPages = Math.ceil(expandData?.length / pageSize);
        setPages(totalPages);
        return expandData;
      });
    } else if (isExpandTab) {
      presentPageData = totalGridData.slice(
        (activePage - 1) * pageSize,
        activePage * pageSize
      );
      let totalPages = Math.ceil(totalGridData?.length / pageSize);
      if (totalPages !== pages) setPages(totalPages);
    } else {
      result = await fetchDataForPage(
        activePage,
        pageSize,
        activeTab?.urlExpand,
        previewLayout?.isFilter ? previewLayout?.filters : activeTab?.filters,
        "",
        true
      );
    }
  
    if (
      parentInteractionFlag &&
      activePage === 1 &&
      parentSelectedIds?.length > 0 &&
      alreadyDisplayedSelectedIds.length !== parentSelectedIds.length
    ) {
      const selectedData = await fetchSelectedDataForPage(activeTab?.urlExpand);
      addingExternalData(
        isExpandTab ? presentPageData : result,
        selectedData
        // []
      );
      setIsLoading(false);
      return;
    } else if (
      parentInteractionFlag &&
      activePage > 1 &&
      removedExternalData.length > 0
    ) {
      addingExternalData(
        isExpandTab ? presentPageData : result,
        removedExternalData
        // alreadyDisplayedSelectedIds
      );
      setIsLoading(false);
      return;
    }
    if (notOdata) {
      setTotalGridData(result);
      const presentPageData = result.slice(
        (activePage - 1) * pageSize,
        activePage * pageSize
      );
      setGridData(presentPageData);
    } else {
      setGridData((isExpandTab || isCallNextLinksForAllData) ? presentPageData : result);
    }
    setIsLoading(false);
    isTabChangeForAll.current = false;
    getAPIcallFunction && getAPIcallFunction(false);
  }, [ setIsLoading,
  searchInitiated,
  notOdata,
  totalGridData,
  activePage,
  pageSize,
  setGridData,
  pageValues,
  records,
  maxPageSize,
  fetchAllRecords]);

  // useEffect(() => {
  //   getAPIcallFunction && getAPIcallFunction(fetchData);
  // }, [fetchData]);

  const onAGridRef = (value) => {
    gridRef.current = value;
    onGridRef && onGridRef(value);
  };

  const onAGridStateRef = (value) => {
    gridStateRef.current = value;
  };
  useEffect(() => {
    getSelectedItems && getSelectedItems(selectedItems);
  }, [selectedItems]);

  const updatingStatesToEmpty = () => {
    setGridRemovedTab("");
  };

  const onTabClick = (ele) => {
    !removedPresentTab && onTabChange && onTabChange(ele);
    onTabSwitch(true);
    !removedPresentTab && setActiveTab(ele);
    !removedPresentTab && setGridRemovedTab("");
    isTabChange.current = true;
    isTabChangeForAll.current = true;
    setSearch("");
    setSearchError("");
    setApiCount(1);
    setPageSize(pageValues[0].value);
    setIsXIconVisible(false);
    setTotalGridData([]);
    setPreviewLayout(null);
    // setActivePage(0);
  };

  const layoutUpdated = async (viewInfo, type, action="") => {
    if (action === "original") {
      setSearch("");
      setSearchError("");
      setIsXIconVisible(false);
    }
    if (type === "filters") {
      setTotalGridData([]);
      setIsLoading(true);
      setPreviewLayout({ ...viewInfo, isFilter: ["preview"].includes(action) });
      const allValue = pageValues?.find(
        (size) => size?.label?.toLowerCase() === "all"
      )?.value;
      const isExpandTab = checkIsExpandTab();
      if (Number(pageSize) === allValue) {
        const result = await fetchDataForPage(
          1,
          allDataForRunwayAPIs ? 0 : maxPageSize,
          activeTab?.urlExpand,
          viewInfo?.filters,
          "",
          true,
          true
        );
        setPageSize(result.length);
        setActivePage(1);
        setPages(1);
        setGridData(result);
        setIsLoading(false);    
        return result.length > 0;
      } else if (isCallNextLinksForAllData) {
        const allData = await fetchDataForPage(
          1,
          allDataForRunwayAPIs ? 0 : maxPageSize,
          activeTab?.urlExpand,
          viewInfo?.filters,
          "",
          true,
          true
        );
        setTotalGridData(allData);
        const presentPageData = allData.slice(
          (activePage - 1) * pageSize,
          activePage * pageSize
        );
        setGridData(presentPageData);
        setActivePage(1);
        setIsLoading(false);
        return presentPageData.length > 0;
      } else if (isExpandTab) {
        setApiCount(viewInfo?.viewButton === "reset" ? 1 : 2);
        const result = await fetchDataForPage(
          1,
          pageSize,
          viewInfo?.urlExpand,
          viewInfo?.filters,
          "",
          true
        );
        if (
          parentInteractionFlag &&
          activePage === 1 &&
          parentSelectedIds?.length > 0
        ) {
          const selectedData = await fetchSelectedDataForPage(
            viewInfo?.urlExpand
          );
          addingExternalData(result, selectedData);
          return;
        } else if (
          parentInteractionFlag &&
          activePage > 1 &&
          removedExternalData.length > 0
        ) {
          addingExternalData(result, removedExternalData);
          return;
        }
        let presentPageData = result.slice(
          (activePage - 1) * pageSize,
          activePage * pageSize
        );
        setGridData(presentPageData);
        setTotalGridData([...result]);
        setActivePage(1);
        setIsLoading(false);
        return result.length > 0;
      } else {
        const result = await fetchDataForPage(
          1,
          pageSize,
          viewInfo?.urlExpand,
          viewInfo?.filters,
          "",
          true
        );
        setGridData(result);
        setActivePage(1);
        setIsLoading(false);
        return result.length > 0;
      }
    } else {
      setPreviewLayout({ ...viewInfo, isColumn: ["preview"].includes(action) });
      // setPreviewLayout({ ...setCheck });
    }
  };

  return (
    <>
      <CSVLink
        data={exportItems}
        filename={exportFileName}
        className="addstyle"
      >
        <button ref={csvRef}>DownLoad CSV</button>
      </CSVLink>
      <CTabs activeTab={activeTab?.name}>
        {isTabsActive && (
          <div
            className={`${
              darkMode ? "dark-tab-background" : "tab-background"
            } d-flex overflow-x overflow-y-clip scrollbar-width-thin w-100`}
          >
            <div className="d-inline-flex">
              <TabLinks
                tabLinks={tabLinks}
                activeTab={activeTab}
                darkMode={darkMode}
                TabClassNames={TabClassNames}
                onTabClick={onTabClick}
                views={views}
                handleTabClose={handleTabClose}
                setTabLinks={setTabLinks}
              />
            </div>
            <div className="d-inline-block align-top position-sticky end-0">
              {(othersOptions?.length > 0 || isExportActive) &&
                !optionsLoading && (
                  <CDropdown>
                    <CDropdownToggle
                      color="secondary"
                      className="options-boarder"
                    >
                      Options
                    </CDropdownToggle>
                    <DropdownPortal>
                      <CDropdownMenu
                        className={
                          darkMode ? "dropdown-option-dark grid-option" : ""
                        }
                      >
                        {othersOptions.map((ele) =>
                          isUserHasOnlyReadAccess &&
                          ele.readPermissionMessage ? (
                            <CTooltip
                              content={ele.readPermissionMessage}
                              key={ele.value}
                            >
                              <CDropdownItem key={ele.value} value={ele?.value}>
                                {ele?.label}
                              </CDropdownItem>
                            </CTooltip>
                          ) : (
                            <CDropdownItem
                              key={ele.value}
                              value={ele?.value}
                              disabled={ele?.disabled}
                              onClick={() => {
                                getOtherOptionChange(ele?.value);
                              }}
                            >
                              {ele?.label}
                            </CDropdownItem>
                          )
                        )}
                        {isExportActive && (
                          <CDropdownItem
                            onClick={() =>
                              performExportFromAPI
                                ? performExportFromAPI()
                                : exportData()
                            }
                          >
                            Export Grid
                          </CDropdownItem>
                        )}
                      </CDropdownMenu>{" "}
                    </DropdownPortal>
                  </CDropdown>
                )}
            </div>
          </div>
        )}
        <CTabContent>
          {tabLinks?.map(
            (ele) =>
              activeTab?.name === ele?.name && (
                <CTabPane data-tab={ele.name} key={ele?.name}>
                  <Box display="flex" flexDirection="column" gap={{xs: "14px"}} margin="16px 8px 28px 8px">
                    <Box display="flex" flexDirection="row" gap="8px" flexWrap="wrap">
                      <Box display="flex" flexDirection="row" gap={{xs: '8px'}}>
                        <Box display="flex" flexDirection="column">
                    {isSearchActive && (
                      <Box margin="auto 0">
                            {SearchInput(
                              search,
                              setSearch,
                              setIsXIconVisible,
                              searchData,
                              isXIconVisible,
                              onClickXIcon,
                              searchPlaceHolder,
                              searchInputDisable
                            )}
                          </Box>
                        )}
                        <Box>
                    {searchError && (
                        <Typography color="error" variant="caption"
                        >
                          {searchError}
                        </Typography>
                      )}
                    </Box>
                        </Box>
                        {renderSearchToggle && renderSearchToggle()}
                      </Box>
                      <Box display="flex" flexDirection="row" alignitems="center" gap="8px">
                        <Tooltip title="Clear" placement="top"     slotProps={{
          tooltip: {
            sx: {
              backgroundColor: "#0B0E13",
              borderRadius: "8px",
              padding: "8px",
              fontSize: "12px",
              lineHeight: "16.34px",
              color: "#fff",
              fontWeight: 400,
              paading: '8px',
            },
          },
        }}>
                        <Button
                          variant="contained"
                          sx={{
                            width: "33px",
                            height: "33px",
                            padding: 0,
                            minWidth: "33px",
                            maxWidth: "33px",
                            minHeight: "33px",
                            maxHeight: "33px",
                            borderRadius: "4px",
                            margin: "auto 0"
                          }}
                          onClick={() => onClickXIcon()}
                          >
                          <img src={ClearIcon} alt="clear" />
                        </Button>
                          </Tooltip>
                        {renderPrintDropDown && renderPrintDropDown()}
                        {isWareHouseDropDown &&
                          renderWarehouseDropDown &&
                          renderWarehouseDropDown()}
                        {isViewUpdateActive && (
                            <AButton
                              text="Create Default View"
                              className="btn btn-primary mr-2 px-4 w-100"
                              onClick={() => createDefaultView()}
                              isLoading={defaultViewLoading}
                            />
                          )}
                        </Box>
                        <Box flex={{xs: 1, md: 'unset'}} marginLeft={{md: "auto"}}>
                    {isViewFilterActive && (
                        <SelectionFields
                          getSelectedTabs={getSelectedTabs}
                          views={sortedViews}
                          setViews={setViews}
                          activeTab={activeTab}
                          ModuleMenuCode={ModuleMenuCode}
                          gridData={gridData}
                          excludedKeys={keysToRemove}
                          viewsWithTabs={viewsWithTabs}
                          viewsTabs={viewsTabs}
                          expandCheck1={expandCheck1}
                          defaultTab={defaultTab}
                          expandValue1={expandValue1}
                          getUpdatedViews={getUpdatedViews}
                          HeaderKeyMappings={HeaderKeyMappings}
                          isUserHasOnlyReadAccess={isUserHasOnlyReadAccess}
                          gridRef={gridRef}
                          gridStateRef={gridStateRef}
                          updatingStatesToEmpty={updatingStatesToEmpty}
                          layoutUpdated={layoutUpdated}
                          setIsLoading={setIsLoading}
                          DynamicFilters={DynamicFilters}
                          excludeFilterColumnKeys={excludeFilterColumnKeys}
                          gridColumns={gridColumns}
                          filterOperatorsNumberKeysOnly={
                            filterOperatorsNumberKeysOnly
                          }
                          previewView={previewLayout ? previewLayout : null}
                          urlEndPoint={urlEndPoint}
                        />
                      )}
                    </Box>
                    </Box>
                    {searchInitiated && (
                    <Box
                      display="flex"
                    >
                      <Box display="flex" alignItems="center" gap="4px">
                        <Typography
                          fontSize="16px"
                          lineHeight="16.8px"
                          color="#0B0E13"
                          fontWeight={400}
                        >
                          {records > 0
                            ? `${records} results found, for a new search`
                            : `There were no results`}
                          , please <b>clear</b> search results.
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  </Box>
                  {isErrorAPI && exceptionMessage ? (
                    <div className="w-100">
                      <HandleMessageFromResponse data={exceptionMessage} />
                    </div>
                  ) : (
                    <>
                      <InfraGrid
                        key={`${activeTab?.viewDetails}-${activeTab?.name}`}
                        data={gridData}
                        isLoading={isLoading}
                        onGridRef={onAGridRef}
                        gridPrimaryKey={gridPrimaryKey}
                        gridColumns={gridColumns}
                        className="grid-height"
                        gridHeight={
                          !ispaginationActive ? "calc(100% )" : gridHeight
                        }
                        allowFiltering={allowFiltering}
                        autoGenerate={autoGenerate}
                        filterMode={filterMode}
                        gridClassName={gridClassName}
                        gridWidth={gridWidth}
                        moving={moving}
                        rowSelection={rowSelection}
                        customRenderedColumns={customRenderedColumns}
                        getSelectedItems={setSelectedItems}
                        rowClasses={rowClasses}
                        rowStyles={rowStyles}
                        selectRowOnClick={selectRowOnClick}
                        onGridStateRef={onAGridStateRef}
                        activeTab={
                          previewLayout?.isColumn ? previewLayout : activeTab
                        }
                        previewLayout={previewLayout}
                        parentSelectedIds={
                          parentSelectedIds.length > 0
                            ? parentSelectedItems
                            : parentSelectedIds
                        }
                        isEmptySelected={isgridEmptySelected}
                        isCheckboxDisabled={isCheckboxDisabled}
                      />
                      {AInfraGridButtons?.length > 0 && (
                        <CCol className="text-right p-2">
                          {AInfraGridButtons.map((button, index) => (
                            <AButton
                              key={index}
                              color={button.color}
                              text={button.text}
                              onClick={button.onClick}
                              disabled={button.disabled}
                              className={button.className}
                            />
                          ))}
                        </CCol>
                      )}
                      {ispaginationActive && (
                        <div className={additionalPagenationClassName}>
                          <Pagination
                            setPageSize={setPageSize}
                            records={records}
                            pages={pages}
                            activePage={activePage}
                            setActivePage={setActivePage}
                            pageSize={pageSize}
                            pagesizes={pageValues}
                            setPages={setPages}
                            isLoading={isLoading}
                            selectedItems={selectedItems}
                            rowSelection={rowSelection}
                          />
                        </div>
                      )}
                    </>
                  )}
                </CTabPane>
              )
          )}
        </CTabContent>
      </CTabs>
    </>
  );
};

export default AInfraGrid;
