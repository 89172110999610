/* eslint-disable react-hooks/exhaustive-deps */
import {
  CButton,
  CCol,
  CFormGroup,
  CInput,
  CInputRadio,
  CLabel,
  CLink,
  CListGroup,
  CListGroupItem,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavItem,
  CTabContent,
  CTabPane,
  CTabs,
  CTooltip,
} from "@coreui/react";
import { useContext, useEffect, useState } from "react";
import FilteredColumns from "./filtered-columns";
import { LoggerContext } from "../../WMS/Common_Helper/services/uiLoginServices";
import { postView, getRoles, getUsers, putView, deleteView } from "../Services/GridAPIService";
import AMultiSelect from "../../../Components/AMultiSelect";
import _ from "lodash";
import handleErrorMessage from "../../../helpers/handleErrorMessage";
import AButton from "../../../Components/AButton";
import { readPermissionMessage } from "../../../Services/UserAccessPermission";
import { gridLayoutLoad } from "./grid-functions";
import { sortItems } from "./sortUtils";

function GridSidebar({
  sidebarOpen = false,
  setSidebarOpen,
  selectedValue,
  layoutUpdated,
  getSelectedTabs,
  gridRef,
  gridStateRef,
  activeTab,
  views,
  setViews,
  ModuleMenuCode,
  gridData,
  excludedKeys,
  updatingStatesToEmpty,
  sidebarProblem=false,
  primaryKeys,
  viewsWithTabs = false,
  viewsTabs = [],
  defaultTab = {label: "", value: ""},
  expandValue1 = "",
  getUpdatedViews,
  HeaderKeyMappings,
  isUserHasOnlyReadAccess,
  setIsLoading,
  DynamicFilters=[],
  excludeFilterColumnKeys="",
  gridColumns=[],
  filterOperatorsNumberKeysOnly=[],
  previewView=null,
  urlEndPoint="",
}) {
  const screenSize = window.matchMedia("(min-width: 992px)").matches;
  const [searchTerm, setSearchTerm] = useState("");
  const [icon, setIcon] = useState(false);
  const [modal, setModal] = useState(false);
  const { logger } = useContext(LoggerContext);
  const [viewName, setViewName] = useState("");
  const [defaultViewName, setDefaultViewName] = useState("");
  const [activeView, setActiveView] = useState("");
  const [permission, setPermission] = useState("AllRoles");
  const [showSpinners, setShowSpinners] = useState([]);
  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [usersOptions, setUsersOptions] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [filteredViews, setFilteredViews] = useState([]);
  const [disableBtn, setDisableBtn] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [buttonData, setButtonData] = useState([
    { icon: "far fa-save", label: "Save view", value: "updateView", disable: false, isUserHasOnlyReadAccess: isUserHasOnlyReadAccess  },
    { icon: "far fa-save", label: "Save as new", value: "newView", disable: false, isUserHasOnlyReadAccess: false },
    { icon: "fa-solid fa-star", label: "Add to favorites", value: "favorite", disable: false, isUserHasOnlyReadAccess: isUserHasOnlyReadAccess },
    { icon: "fa-solid fa-trash", label: "Delete view", value: "delete", disable: false, isUserHasOnlyReadAccess: isUserHasOnlyReadAccess },
    { icon: "fa-solid fa-arrows-rotate", label: "Reset to original", value: "original", disable: false, isUserHasOnlyReadAccess: false },
  ]);
  const [filterButtonData, setFilterButtonData] = useState([
    {
      nextRow: false,
      label: "Preview",
      value: "preview",
      btnClass: "btn btn-primary w-100",
      disable: true,
      isUserHasOnlyReadAccess: false,
    },
    {
      nextRow: true,
      label: "Reset",
      value: "reset",
      btnClass: "btn btn-primary w-100",
      disable: true,
      isUserHasOnlyReadAccess: false,
    },
    {
      nextRow: true,
      label: "Cancel",
      value: "cancel",
      btnClass: "btn btn-secondary w-100",
      disable: false,
      isUserHasOnlyReadAccess: false,
    },
    {
      nextRow: false,
      label: "Save",
      value: "save",
      btnClass: "btn btn-success w-100",
      disable: true,
      isUserHasOnlyReadAccess: isUserHasOnlyReadAccess,
    },
  ]);
  let filterSelection = sidebarProblem;
  const radioButtons = [
    { label: "All Roles", value: "AllRoles" },
    { label: "Roles", value: "Roles" },
    { label: "All Users", value: "AllUsers" },
    { label: "Users", value: "Users" }
  ]
  const [selectedTab, setSelectedTab] = useState([]);
  const [viewFilters, setViewFilters] = useState([]);
  const [userAccessError, setUserAccessError] = useState("");
  const [usersApiError, setUsersApiError] = useState(false);
  const [rolesApiError, setRolesApiError] = useState(false);
  const [isViewResetToOriginal, setIsViewResetToOriginal] = useState(false);
  const [isSystemView, setIsSystemView] = useState(false);
  const [systemViewMessage, setSystemViewMessage] = useState("");
  // const darkMode = useSelector((state) => state.darkMode);
  const viewNameMaxLength = 200;

  useEffect(() => {
    updateActiveTabView(activeTab);
    setSelectedTab(defaultTab);
    // eslint-disable-next-line
  }, [activeTab]);

  useEffect(()=>{
    setViewName(defaultViewName);
    setPermission("AllRoles");
    setSelectedUsers([]);
    setSelectedRoles([]);

  },[modal])

  useEffect(() => {
    if (views.length > 0) {
      views.forEach(vieww => {
        if (vieww.isActiveTab) {
          // Regular expression to match "(number)" pattern
          const regex = /\((\d+)\)$/;
          // Convert all filenames to lowercase for case-insensitive comparison
          const lowerCaseViews = generateViewNames(views);
          // Remove parentheses and number from the end of the file name
          const baseName = vieww.name.replace(regex, '');
          // Find filenames that start with the base name (case-insensitive)
          const matchingNames = lowerCaseViews.filter(name => name.startsWith(baseName.toLowerCase()));
          // Extract numeric values from matching filenames
          const numericValues = matchingNames.map(name => {
              const match = name.match(regex);
              return match ? parseInt(match[1]) : NaN;
          });
          // Filter out non-numeric values and find the maximum numeric value
          const maxNumericValue = _.max(_.filter(numericValues, value => !isNaN(value)));
          // Determine the count for the duplicated file name
          const count = isNaN(maxNumericValue) ? 1 : maxNumericValue + 1;
          // Append the count to the duplicated file name
          let viewNameee = `${baseName}(${count})`;
          let index = views.findIndex(viw => viw.name === viewNameee);
          if (index !== -1) {
            viewNameee = `${views[index].name}(1)`;
          }
          setDefaultViewName(viewNameee);
          setViewName(viewNameee);
          setActiveView(vieww);
          toggleActionButtons(vieww);
        }
      });
    }
    // eslint-disable-next-line
  }, [views]);

  useEffect(()=>{
    ValuesFilter();
  },[selectedTab])

  useEffect(() => {
    if (previewView && activeView) {
      const updatedView = { ...activeView }; // Create a new object
  
      if (!previewView.isColumn) {
        updatedView.viewDetails = activeTab?.viewDetails;
      } else {
        const details = JSON.parse(previewView.viewDetails);
        const tabDetails = JSON.parse(updatedView.viewDetails);
        tabDetails.columns = details?.columns;
        updatedView.viewDetails = JSON.stringify(tabDetails);
      }
  
      if (!previewView.isFilter) {
        updatedView.filters = activeTab?.filters;
      } else {
        updatedView.filters = previewView.filters;
      }
  
      setActiveView(updatedView); // Set state with the new object
    }
  }, [previewView]);

  useEffect(() => {
    if (usersApiError && rolesApiError) {
      setUserAccessError("*User does not have access for roles/users.");
    } else if (usersApiError && (permission === "Users" || permission === "AllUsers")) {
      setUserAccessError("*User does not have access for users.");
    } else {
      setUserAccessError("");
    }

  }, [rolesApiError, usersApiError, permission])

  useEffect(() => {
    console.log("frifjrifjirj")
    LoadUsersAndRoles();
    toggleActionButtons(activeTab);
    // eslint-disable-next-line
  }, [])

  const updateActiveTabView = (activeViewInfo) => {
    views.forEach(view => {
      if (view.name === activeViewInfo.name) {
        view.isActiveTab = true;
      } else {
        view.isActiveTab = false;
      }
    });
  };

  function generateViewNames(gridviews) {
    const nameCountMap = {};
    const result = [];
    
    _.forEach(gridviews, (view) => {
      const baseName = _.toLower(view.name);
      nameCountMap[baseName] = (nameCountMap[baseName] || 0) + 1;
      
      if (nameCountMap[baseName] === 1) {
        result.push(view.name);
      } else {
        result.push(`${view.name}(${nameCountMap[baseName] - 1})`);
      }
    });
    
    return result;
  }

  const toggleActionButtons = (activeView) => {
    let actionButtons = buttonData;
    if (activeView.type === "System") {
      const favoriteButtonIndex = actionButtons.findIndex(button => button.value === "updateView");
      const deleteViewIndex = actionButtons.findIndex(item => item.value === "delete");
      if (favoriteButtonIndex !== -1) {
        actionButtons[favoriteButtonIndex].disable = true;
      }
      if (deleteViewIndex !== -1) {
        actionButtons[deleteViewIndex].disable = true;
      }
    } else {
      const favoriteButtonIndex = actionButtons.findIndex(button => button.value === "updateView");
      const deleteViewIndex = actionButtons.findIndex(item => item.value === "delete");
      if (favoriteButtonIndex !== -1) {
        actionButtons[favoriteButtonIndex].disable = false;
      }
      if (deleteViewIndex !== -1) {
        actionButtons[deleteViewIndex].disable = false;
      }
    }
    const favoriteButtonIndex = actionButtons.findIndex(button => button.value === "favorite");
    if (favoriteButtonIndex !== -1) {
      actionButtons[favoriteButtonIndex].label = activeView.isFavorite ? "Remove from favorites" : "Add to favorites";
    }
    setButtonData(actionButtons);
  }

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const showModal = () => {
    setModal(true);
  }

  const hideModal = () => {
    setModal(false);
  }

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    setIcon(!!searchTerm);
    let filteredResults = [];
    if (searchTerm) {
      // Filter data based on the search term
      let chec = [];
      if (selectedTab.value === viewsTabs[0]?.value) {
        chec = views.filter(view => !view?.urlExpand?.toLowerCase()?.includes(expandValue1) && view.name.toLowerCase().includes(searchTerm.toLowerCase()));
      } else {
        chec = views.filter(view => view?.urlExpand?.toLowerCase()?.includes(expandValue1) && view.name.toLowerCase().includes(searchTerm.toLowerCase()));
      }
      filteredResults = sortItems(chec);
    } else {
      filteredResults = []
    }
    setFilteredViews(filteredResults);
  };

  const handleItemClick = (item) => {
    setActiveView(item);
    getSelectedTabs(item);
    toggleActionButtons(item);
    updatingStatesToEmpty();
  };

  const UpdateView = () => {
    //asd  
    setShowSpinners("updateView");
    const layout = gridStateRef?.current?.getStateAsString([]);
    let activeView = activeTab;
    activeView.viewDetails = layout;
    PutViewDetails(activeView);
  }

  const PutViewDetails = async (activeView, loader=false) => {
    await putView(activeView, logger).then((res) => {
      hideModal();
      setShowSpinners([]);
      getUpdatedViews()
      if (res && res.statusCode === 200) {
        console.log("View updated SuccessFully");
      } else {
        console.log("Unable to update view");
      }
    }).catch((err) => {
      console.log("Exception occured while updating view");
      hideModal();
      setShowSpinners([]);
      console.log(err);
    }).finally(() => {
      loader && setIsLoading(false)
    });
  }

  const deleteThisView = () => {
    setShowSpinners("deleteView");
    deleteView(activeView.viewId, logger).then((res) => {    
      setShowSpinners([]);
      getUpdatedViews(true)
      console.log("View Deleted successfull");
    }).catch((err) => {
      hideModal();
      setShowSpinners([]);
      console.log(err);
    })
  }

  const validatePayload = (component) => {
    if (viewName?.length > viewNameMaxLength) {
      return <><CLabel className="width-100percent cpl-25percent text-danger">*{handleErrorMessage("AIMS360_Runway_WMS_Validation_MaxLength_1051")}</CLabel></>
    }
    if (component === "ViewName") {
      if (viewName === "" || !viewName) {
        return <><CLabel className="width-100percent cpl-25percent text-danger">{handleErrorMessage("AIMS360_Runway_Standard_Grid_1803")}</CLabel></>
      }

      if (views.findIndex(viw => viw.name === viewName) !== -1) {
        return <><CLabel className="width-100percent cpl-25percent text-danger">{handleErrorMessage("AIMS360_Runway_Standard_Grid_1804")}</CLabel></>
      }
    }

    if (component === "Role" && permission === "Roles") {
      if (selectedRoles.length === 0) {
        return <><CLabel className="width-100percent cpl-25percent text-danger">{handleErrorMessage("AIMS360_Runway_Standard_Grid_1801")}</CLabel></>
      }
    }

    if (component === "User" && permission === "Users") {
      if (selectedUsers.length === 0) {
        return <><CLabel className="width-100percent cpl-25percent text-danger">{handleErrorMessage("AIMS360_Runway_Standard_Grid_1802")}</CLabel></>
      }
    }
  }

  const saveAsNew = () => {
    setShowSpinners("newView");
    const layout = gridStateRef?.current?.getStateAsString([]);
    const payLoad = {
      "name": viewName,
      "category": activeView?.category,
      "viewDetails": layout,
      "filters": activeView?.filters,
      "urlExpand": activeView?.urlExpand,
      "type": "User",
      "roleAssignement": GetSelectedRoleIds(),
      "userAssignement": GetSelectedUserIds(),
      "runwayModuleId": ModuleMenuCode,
      "urlEndPoint": urlEndPoint || activeView?.urlEndPoint,
      "subcategory": activeView?.subcategory
    }

    postView(payLoad, logger).then((res) => {
      hideModal();
      setShowSpinners([]);
      getUpdatedViews(true);
    }).catch((err) => {
      setUserAccessError(err?.response?.data?.runwayError?.message || err?.response?.data?.aims360Error?.message);
      setShowSpinners([]);
      console.log(err);
    })
  }

  const LoadUsersAndRoles = () => {
    LoadRoles();
    LoadUsers();
  }

  const LoadRoleOptions = () => {
    let roleOpts = [];
    roles.forEach(role => {
      roleOpts.push({ label: role.Name, value: role.RoleId });
    });
    setRoleOptions(roleOpts);
  }

  const LoadUserOptions = () => {
    let userOpts = [];
    users.forEach(user => {
      if (user.UserName)
      userOpts.push({ label: user.UserName, value: user.UserId });
    });
    setUsersOptions(userOpts);
  }

  const LoadUsers = () => {
    getUsers(logger).then((res) => {
      if (res && res.status === 200) {
        setUsers(res.data.value);
      }
    }).catch((err) => {
      console.log("Error while loading roles");
      if (err?.response?.status === 403) {
        setUsersApiError(true);
      }
      console.log(err);
    })
  }

  const LoadRoles = () => {
    getRoles(logger).then((res) => {
      console.log("Roles loaded succssfully");
      if (res && res.status === 200) {
        setRoles(res.data.value);
      }
    }).catch((err) => {
      console.log("Error while loading roles");
      if (err?.response?.status === 403) {
        setRolesApiError(true);
      }
      console.log(err);
    })
  }
  const resetToOriginal = () => {
    setShowSpinners("original");
    const initialView = views.find(ele => ele.name === activeTab?.name)
    setIsViewResetToOriginal(true);
    setShowSpinners([]);
    const flterObj = {
      data: initialView?.filters ? JSON.parse(initialView?.filters) : [],
      viewDetails: initialView?.viewDetails,
      action: "reset",
      type: "filters",
      subAction: "original",
    };
    GetUpdatedFilters(flterObj);
    gridLayoutLoad(initialView, gridRef, gridStateRef);
  }

  const handleClickButton = async (value) => {
    toggleActionButtons(activeTab);
    if (value === "updateView") {
      if (activeTab?.type.toLowerCase() === 'system') {
        setIsSystemView(true);
        setSystemViewMessage("You are now viewing the default view. Changes cannot be made in this view. Please switch to the user view to make any modifications.")
        return;
      }
      //Update existing view
      UpdateView();
    } else if (value === "favorite") {
      //set current view as favorite view
      setViewAsFav(!activeTab?.isFavorite);
    } else if (value === "delete") {
      if (activeTab?.type.toLowerCase() === 'system') {
        setIsSystemView(true);
        setSystemViewMessage("You are currently in the default view where deletions are not allowed. To delete items, please switch to the user view")
        return;
      }
      //delete view
      deleteThisView();
    } else if (value === "original") {
      //Reset view to original
      resetToOriginal();
    } else {
      LoadRoleOptions();
      LoadUserOptions();
      showModal();
    }
  };

  const handleViewNameChange = (e) => {
    const value = e.target.value;
    const nameRegex = /^[a-zA-Z0-9][a-zA-Z0-9!@#$ %^&*()_+{}[\]:;<>,.?/~\\-]*$/;
    let valid=nameRegex.test(value);
    if(valid){
      setViewName(value);
      setUserAccessError("");
      const alreadyexist=views.findIndex(viw => viw.name === value?.trim()) !== -1;
      const isViewNameNotEmpty = value?.trim().length > 0;
      const vl = (permission === 'AllUsers') || (permission === 'AllRoles');
      const Roles=(permission==='Roles')
      const Users=(permission === 'Users')
      const usersValid=Users?(selectedUsers?.length>0):false;
      const Rolesvalid=Roles?(selectedRoles?.length>0):false;
      const v1=vl?vl:false;
      const isvalid = !alreadyexist;

      const isVisible =(isViewNameNotEmpty&&isvalid)&&(((usersValid)||(Rolesvalid))||(v1));
      if (value?.length > viewNameMaxLength) {
        setDisableBtn(true);
        return;
      }
      setDisableBtn(!isVisible);
    }
    else{
      setViewName(null);
      setDisableBtn(true);
    }
  }

  const OnTabSelected = (tab) => {
    setSelectedTab(tab);
    ValuesFilter();
  };

  const ValuesFilter = () => {
    let chec = []
    if (selectedTab.value === viewsTabs[0]?.value) {
      chec = views.filter(view => !view?.urlExpand?.toLowerCase()?.includes(expandValue1));
    } else {
      chec = views.filter(view => view?.urlExpand?.toLowerCase()?.includes(expandValue1));
    }
    const sortedData = sortItems(chec);

    setViewFilters(sortedData);
  }

  const sidebarActionButtons = () => {
    return(<CSidebarNavItem className="mt-5">
        <CRow>
          {buttonData.map((ele) => (
            <CCol md="6" key={ele.label}>
              {ele.isUserHasOnlyReadAccess ? 
                <CTooltip
                  content={readPermissionMessage}
                  placement="top"
                >
                  <CLink>
                    <AButton
                      text={ele.label}
                      color="primary"
                      className="searchItems h-auto d-flex align-items-center"
                      disabled
                    />
                  </CLink>
                </CTooltip> :
              <CButton
                onClick={() => handleClickButton(ele.value)}
                className="searchItems h-auto d-flex align-items-center"
                color="primary"
                title={ele.label}
              >
              <div className="d-flex align-items-center w-100">
                <i className={`${ele.icon} pr-4 flex-shrink-0`} />
                <span className="button-text-ellipsis">
                  {showSpinners.includes(ele.value) ? (
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  ) : (
                    ele.label
                  )}
                </span>
              </div>
            </CButton>
            }
            </CCol>
          ))}
        </CRow>
      </CSidebarNavItem>)
  }

  const sidebarSearchPannel = () => {
    return <CSidebarNavItem>
      <div className="d-flex miximize-icon-search searchItems position-relative">
        <CInput
          style={{ height: "30px" }}
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search view"
          className={`${icon ? "padding-right-35" : ""}`}
        />
        {icon ? (
          <i
            className="fa fa-times icon text-danger"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSearchTerm("");
              setIcon(false);
              setViews && setViews(views)
            }}
          ></i>
        ) : (
          <i
            className="fa fa-search icon"
            style={{ color: "#3c4b64" }}
          ></i>
        )}
      </div>
    </CSidebarNavItem>
  }

  const renderViews = (listViews=[]) => {
    return (<>{sidebarSearchPannel()}
    {/* Display the filtered results */}
    <CSidebarNavItem>
      <CListGroup className="searchItems listGroup">
        {listViews?.length === 0 ? <div className="d-flex align-items-center justify-content-center ">No views found</div> : listViews.map((item) => (
          <CListGroupItem
            href="#"
            active={item.name === activeView.name}
            onClick={() => handleItemClick(item)}
            className="py-0"
            key={item.viewId}
          >
            <CRow>
              <CCol md="11" xs="11" className="pr-0">{item.name} {item.type.toLowerCase() === "system" && "(Default)"}</CCol>
              <CCol md="1" xs="1" className="p-0">
                {item.isFavorite === true ?
                  <i className="fa fa-star favorite-color" ></i>
                :
                  <i className="far fa-star"></i>
                }
              </CCol>
            </CRow>
          </CListGroupItem>
        ))}
      </CListGroup>
    </CSidebarNavItem>
    {sidebarActionButtons()}</>)
  }

  const sidebarForViews = () => {
    return viewsWithTabs ? <CTabs activeTab={selectedTab.value}>
      <CNav variant="tabs" className="height-26">
        {
          viewsTabs?.map((tabs,index) => (
            <CNavItem
              key={tabs.value}
              onClick={() => OnTabSelected(tabs)}
              className='h-100 d-flex'
            >
              {/* <CNavLink data-tab={tabs.value} className={`${darkMode ? "view-tab-dark-inActive" : "nav-link-inActive"} px-3 py-1`}> */}
              <CNavLink data-tab={tabs.value} className="nav-link-inActive px-3 py-1">
                {tabs.label}
              </CNavLink>
              {index !== viewsTabs?.length - 1 && <span className="d-flex align-items-center text-secondary">|</span>}
            </CNavItem>
          ))
        }
      </CNav>
      <CTabContent>
        {viewsTabs.map((ele) => (
          <CTabPane data-tab={ele.value}>
            {renderViews(searchTerm ? filteredViews : viewFilters)}
          </CTabPane>
        ))}
      </CTabContent>
    </CTabs> : renderViews(searchTerm ? filteredViews : viewFilters)
  }

  const sidebarHeader = () => {
    return <>
      <CSidebarBrand
        className="sidebar-bg flex-column align-items-stretch"
        height="30"
      >
        <CRow>
          <CCol md="10" xs="8" className="d-flex pl-4 align-items-center">
            <h4>
              {selectedValue === "views"
                ? "Views"
                : selectedValue === "filters" && "Filters"}
            </h4>
          </CCol>
          <CCol md="2" xs="2" className="d-flex align-items-center">
            {/* <CButton className="text-white" >          </CButton> */}
            <i className="fa-solid fa-x font-20" onClick={toggleSidebar}></i>
          </CCol>
        </CRow>
      </CSidebarBrand>
    </>
  }

  const UpdateSideBar = (e) => {
    setSidebarOpen(e);
    setTimeout(() => {
      setSidebarOpen(e);
    }, 0);
  }

  const GetUpdatedFilters = async (e) => {
    if (e) {
      const updatedView = {
        ...activeTab,
        filters: JSON.stringify(e.data),
        viewButton: e.action,
        ...(previewView?.isColumn && {viewDetails: previewView?.viewDetails}),
        isColumn: previewView?.isColumn
      };
  
      if (e?.viewDetails) {
        updatedView.viewDetails = e?.viewDetails;
      }
      if (e.action === 'save') {
        // Save the filters
        const result = await layoutUpdated(updatedView, "filters", e?.subAction);
        if (result) {
          PutViewDetails(updatedView);
          UpdateSideBar(false);
        } else {
          toggleActionButtonsForAll(updatedView, false);
        }
      } else {
        // Preview filters
        setActiveView(updatedView);
        layoutUpdated(updatedView, "filters", e?.subAction);
      }
    }
  }

  const GetUpdateColumns = (e) => {
    if (e) {
      const updatedViewDetails = JSON.parse(activeTab.viewDetails);
      updatedViewDetails.columns = e.data;
      const updatedActiveView = {
        ...activeTab,
        viewDetails: JSON.stringify(updatedViewDetails),
        ...(previewView?.isFilter && {filters: previewView?.filters}),
        isFilter: previewView?.isFilter
      };
      layoutUpdated(updatedActiveView, "columns", e?.subAction);
      if (e.action === 'save') {
        setIsLoading(true);
         // save columns
        PutViewDetails(updatedActiveView, true);
      } else {
        // preview colu,ns
        if (updatedActiveView?.viewDetails && gridStateRef && gridRef) {
          gridLayoutLoad(updatedActiveView, gridRef, gridStateRef);
        }
    
      }
    }
  }

  const setViewAsFav = (value) => {
    let activeView = activeTab;
    setShowSpinners("favorite");
    activeView.isFavorite = value;
    const presentView = _.find(views, { viewId: activeView?.viewId });
    activeView.viewDetails = presentView?.viewDetails;
    activeView.filters = presentView?.filters
    PutViewDetails(activeView);
  }

  const GetSelectedRoleIds = () => {
    let roleids = [];
    if (permission === "AllRoles") {
      roleids = roles.map(role => role.RoleId);
    } else {
      roleids = selectedRoles.map(role => role.value);
    }
    return roleids;
  }

  const GetSelectedUserIds = () => {
    let userIds = [];
    if (permission === "AllUsers") {
      userIds = users.map(user => user.UserId)
    } else {
      userIds = selectedUsers.map(user => user.value)
    }
    return userIds;
  }

  const handlesetselectedUsers=(e)=>{
    setSelectedUsers(e);
    const vl = permission !== 'AllUsers' && permission !== 'AllRoles';
    const isViewNameNotEmpty = viewName?.trim().length > 0;
    const alreadyexist=views.some(viw => viw.name === viewName?.trim());
    const isvalid = !alreadyexist;
    const user=e?.length>0 
    const isVisible = vl && isViewNameNotEmpty && user && isvalid;
    setDisableBtn(!isVisible);
  }

  const handlesetSelectedRoles=(e)=>{
    setSelectedRoles(e)
    setUserAccessError("");
    const vl = permission !== 'AllUsers' && permission !== 'AllRoles';
    const isViewNameNotEmpty = viewName?.trim().length > 0;
    const alreadyexist=views.some(viw => viw.name === viewName?.trim());
    const isvalid = !alreadyexist;
    const Role=e?.length>0
    const isVisible = vl && isViewNameNotEmpty && Role && isvalid;
    setDisableBtn(!isVisible);
  }

  const toggleActionButtonsForAll = (activeViewInfo, value, action = "") => {
    const actionButtons = filterButtonData;
    if (activeViewInfo.type === "System") {
      const saveButtonIndex = actionButtons.findIndex(
        (button) => button.value === "save"
      );
      if (saveButtonIndex !== -1) {
        actionButtons[saveButtonIndex].disable = true;
      }
    } else {
      const saveButtonIndex = actionButtons.findIndex(
        (button) => button.value === "save"
      );
      if (saveButtonIndex !== -1) {
        actionButtons[saveButtonIndex].disable = action === "preview" ? false : value;
      }
    }
    const resetButtonIndex = actionButtons.findIndex(
      (button) => button.value === "reset"
    );
    if (resetButtonIndex !== -1) {
      actionButtons[resetButtonIndex].disable = action === "preview" ? false : value;
    }
    const previewButtonIndex = actionButtons.findIndex(
      (button) => button.value === "preview"
    );
    if (previewButtonIndex !== -1) {
      actionButtons[previewButtonIndex].disable = action === "preview" ? true : value;
    }

    setFilterButtonData(actionButtons);
  };

  const handleScroll = (event) => {
    event.stopPropagation();
  };

  const handleDropdownToggle = (isOpen) => {
    if (isOpen) {
      setIsDropdownOpen(isOpen);
    } else {
      setTimeout(() => {
        setIsDropdownOpen(isOpen);
      },100)
    }
  };

  const saveAsNewViewModal = () => {
    return <CModal show={modal} onClose={hideModal} className="custom-modal">
        <CModalHeader closeButton><b>Save as new</b></CModalHeader>
        <CModalBody className={`custom-modal-body  ${isDropdownOpen ? 'expanded' : ''}`}>
          <CFormGroup className='wms_row'>
            <CLabel className='labelstyle d-flex justify-content-center align-items-center'><span className="text-danger">*</span>View name</CLabel>
            <CInput
              type="text"
              name="viewName"
              placeholder="Enter view name"
              value={viewName}
              className="pl-1 mt-2"
              onChange={(e) => {
                handleViewNameChange(e);
              }}
            />
          </CFormGroup>
          {validatePayload("ViewName")}
          <CFormGroup className='wms_row'>
            <CLabel className='labelstyle d-flex justify-content-center align-items-center'><span className="text-danger">*</span>Permissons</CLabel>
              <div className="row pl-4">
              {radioButtons.map((ele) => {
                return (
                  <CFormGroup key={ele.value} className={`col-6 col-md-3 ${ele.value !== "Users" && 'pr-5'} pt-2 nowrap wms_row`}>
                    <CInputRadio name={ele.value} checked={permission === ele.value} onChange={(e) => { handleChangeRadioValue(ele.value); }} />
                    <CLabel><b>{ele.label}</b></CLabel>
                  </CFormGroup>
                )
              })}
              </div>
          </CFormGroup>
          {permission === "Roles" && <>
            <CFormGroup className='wms_row multiselect-container'>
              <AMultiSelect
                className="py-0 width-100percent cpl-25percent grid-dropdown custom-multiselect"
                name="Roles"
                options={roleOptions}
                value={selectedRoles}
                onChange={(e) => { handlesetSelectedRoles(e);}}
                labelledBy="Select"
                overrideStrings={{
                  selectSomeItems: "Select roles...",
                  allItemsAreSelected: "All roles are selected",
                }}
                onMenuToggle={(isOpen) => handleDropdownToggle(isOpen)}
                closeOnChangedValue={false}
              />
            </CFormGroup>
            {validatePayload("Role")}
          </>}

          {permission === "Users" && <>
            <CFormGroup className='wms_row multiselect-container'>
            <AMultiSelect
              className="py-0 width-100percent cpl-25percent grid-dropdown custom-multiselect"
              name="User"
              options={usersOptions}
              value={selectedUsers}
              onChange={handlesetselectedUsers}
              labelledBy="Select"
              overrideStrings={{
                selectSomeItems: "Select users...",
                allItemsAreSelected: "All users are selected",
              }}
              onMenuToggle={(isOpen) => handleDropdownToggle(isOpen)}
              closeOnChangedValue={false}
            />
          </CFormGroup>
          {validatePayload("User")}
          </>}
          <div className="d-flex justify-content-center text-danger">{userAccessError}</div>
        </CModalBody>
        <CModalFooter>
          {isUserHasOnlyReadAccess ?
          <CTooltip
            content={readPermissionMessage}
            placement="top"
          >
            <CLink>
              <AButton
                text="Save"
                color="primary"
                disabled
              />
            </CLink>
          </CTooltip> :
          <CButton color="primary" onClick={() => saveAsNew()} disabled={disableBtn || userAccessError || showSpinners.includes("newView")} >
            {showSpinners.includes("newView") ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Save"}
          </CButton>}{' '}
          <CButton color="secondary" onClick={hideModal} >Cancel</CButton>
        </CModalFooter>
      </CModal>
  }

  const systemViewModal = () => {
    return <CModal show={isSystemView} onClose={() => {setIsSystemView(false)}}>
        {/* <CModalHeader closeButton><b>Save as new</b></CModalHeader> */}
        <CModalBody>
          {systemViewMessage}
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => {setIsSystemView(false)}}>Cancel</CButton>
        </CModalFooter>
      </CModal>
  }

  const handleChangeRadioValue = (value) => {
    setPermission(value);
    setUserAccessError("");
    if(viewName!=null){
      const vl = (value === 'AllUsers') || (value === 'AllRoles');
      const Roles=(value==='Roles') 
      const Users=(value === 'Users')
      const isViewNameNotEmpty = viewName?.trim().length > 0;
      const alreadyexist=views.some(viw => viw.name === viewName?.trim());
      const isvalid = !alreadyexist;
      const usersValid=Users?(selectedUsers?.length>0):false;
      const Rolesvalid=Roles?(selectedRoles?.length>0):false;
      const v1=vl?vl:false;

      const isVisible =(isViewNameNotEmpty&&isvalid)&&(((usersValid)||(Rolesvalid))||(v1));
      setDisableBtn(!isVisible);
    }
    else{
      setDisableBtn(true)
    }
  }

  const onShowChange = (e) => {
    if (filterSelection) {
      filterSelection = false;
      return
    }
    setSidebarOpen(e)
  }

  const updateFilterCheck = () => {
    filterSelection = true;
  }

  return (
    <div onMouseOutCapture={() => {filterSelection = false}}>
      <CSidebar
        show={sidebarOpen}
        onShowChange={(e) => {onShowChange(e)}}
        aside
        overlaid
        colorScheme="light"
        // style={{ zIndex: "100001" }}
        className={`${sidebarOpen === true && screenSize && "sidebar-width"}`}
        onScroll={handleScroll}
      >
        {sidebarHeader()}
        <CSidebarNav>
          {selectedValue === "views" ?
            sidebarForViews()
            :
            <FilteredColumns
              getUpdatedFilters={GetUpdatedFilters}
              getUpdatedColumns={GetUpdateColumns}
              activeView={activeView}
              updateSidebar={UpdateSideBar}
              updateFilterCheck={updateFilterCheck}
              views={views}
              gridData={gridData}
              excludedKeys={excludedKeys}
              primaryKeys={primaryKeys}
              toggleActionButtonsForAll={toggleActionButtonsForAll}
              setFilterButtonData={setFilterButtonData}
              filterButtonData={filterButtonData}
              isViewResetToOriginal={isViewResetToOriginal}
              setIsViewResetToOriginal={setIsViewResetToOriginal}
              HeaderKeyMappings={HeaderKeyMappings}
              setIsSystemView={setIsSystemView}
              setSystemViewMessage={setSystemViewMessage}
              DynamicFilters={DynamicFilters}
              excludeFilterColumnKeys={excludeFilterColumnKeys}
              gridColumns={gridColumns}
              filterOperatorsNumberKeysOnly={filterOperatorsNumberKeysOnly}
              previewView={previewView}
            />
          }
        </CSidebarNav>
        {saveAsNewViewModal()}
        {systemViewModal()}
      </CSidebar>
    </div >
  );
}

export default GridSidebar;
